import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { QuickFilter } from '../quick-filter';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { ReactSelect } from '../../ui';
//////////////////////////////////////////////////

const INPUT_FILTER_TYPES = [
  GC.ADDITIONAL_FILTER_TYPE_BILL_TO_NAME,
  GC.ADDITIONAL_FILTER_TYPE_INVOICE_NUMBER,
  GC.ADDITIONAL_FILTER_TYPE_CLO_PRIMARY_REFERENCE_VALUE,
];

const getOptions = (props: Object) => {
  const {
    asyncUnitIdList,
    additionalFilterType,
    asyncDriverListFullName,
    asyncBranchChildrenWithShared,
  } = props;

  const filterType = G.getValueFromObject(additionalFilterType);

  if (R.equals(filterType, GC.ADDITIONAL_FILTER_TYPE_BRANCH)) {
    return G.mapNameGuidToLabelValue(asyncBranchChildrenWithShared);
  }

  if (R.equals(filterType, GC.ADDITIONAL_FILTER_TYPE_DRIVER)) {
    const options = R.map((item: Object) => {
      const { fullText } = G.getUserInfo(item);

      const value = G.getGuidFromObject(item);

      return { value, label: fullText };
    }, asyncDriverListFullName);

    return options;
  }

  if (R.equals(filterType, GC.ADDITIONAL_FILTER_TYPE_TRUCK)) {
    const options = R.map(({ guid, unitId }: Object) => ({
      [GC.FIELD_VALUE]: guid,
      [GC.FIELD_LABEL]: unitId,
    }), asyncUnitIdList);

    return options;
  }

  return [];
};

const FilterValue = (props: Object) => {
  const {
    typeValue,
    handleChangeSelect,
    handleInputKeyPress,
    additionalFilterValue,
    setAdditionalFilterValue,
    allowSetAdditionalFiltersImmediately,
  } = props;

  if (G.isNilOrEmpty(typeValue)) return null;

  if (R.includes(typeValue, INPUT_FILTER_TYPES)) {
    const inputProps = {
      mr: 10,
      height: 36,
      borderRadius: '4px',
      borderColor: G.getTheme('colors.veryLightGrey'),
    };

    return (
      <QuickFilter
        withoutLabel={true}
        inputProps={inputProps}
        onKeyPressHandler={handleInputKeyPress}
        handleSetFilter={setAdditionalFilterValue}
        placeholder={G.getWindowLocale('titles:filter-value', 'Filter Value')}
        allowSetAdditionalFiltersImmediately={allowSetAdditionalFiltersImmediately}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      isMulti={true}
      useMaxHeight={true}
      options={getOptions(props)}
      valueContainerMaxHeight={34}
      value={additionalFilterValue}
      onChange={handleChangeSelect}
      shouldNotGetValueFromOptions={true}
      placeholder={G.getWindowLocale('titles:filter-value', 'Filter Value')}
      additionalStyles={{
        container: (baseStyles: Object) => ({
          ...baseStyles,
          width: 250,
          marginRight: 10,
        }),
      }}
    />
  );
};

const enhance = compose(
  withHandlers({
    handleChangeSelect: (props: Object) => (value: any) => {
      const { setAdditionalFilterValue } = props;

      setAdditionalFilterValue(value);
    },
    handleInputKeyPress: (props: Object) => (value: string) => {
      const { setAdditionalFilterValueOnEnter } = props;

      setAdditionalFilterValueOnEnter(value);
    },
  }),
  pure,
);

export default enhance(FilterValue);
