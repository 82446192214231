import React from 'react';
import * as R from 'ramda';
import { Field, reduxForm } from 'redux-form';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../text';
import { HoveringTitle } from '../../hovering-title';
import { ActionsDropdown } from '../../actions-dropdown';
// features
import { AuthWrapper } from '../../../features/permission';
import PC from '../../../features/permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from './../../../svgs';
// ui
import { Box, Flex, ReactSelect, RelativeBox, AbsoluteBox } from '../../../ui';
// edit-report
import { getValue } from '../helpers';
import { SET_REPORT_NAME } from '../constants';
import { GenerateFile, ReportWrapper, ReportPanelOptionWrapper } from '../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withProps(({ reportType, selectReportFormName }: Object) => ({
    enableReinitialize: true,
    form: G.ifElse(
      G.isNotNilAndNotEmpty(selectReportFormName),
      selectReportFormName,
      R.or(reportType, SET_REPORT_NAME),
    ),
  })),
  reduxForm(),
  withHandlers({
    handleSetDefaultAction: (props: Object) => (event: Event, report: Object) => {
      G.stopPropagation(event);
      const reqBody = {
        reportGuid: report.guid,
        reportType: report.type,
      };
      props.changeDefaultReportRequest(reqBody);
    },
  }),
  pure,
);

const renderOptions = (options: Array) => (
  options.map((option: Object) => ({
    value: option.name,
    ...option,
  }))
);

const exportFileType = (props: Object) => ([
  {
    label: 'xls',
    action: props.handleExportReportFile,
    text: G.getWindowLocale('actions:export-exl'),
  },
]);

const getIconColor = (condition: boolean = false) => G.getThemeByCond(
  condition,
  'colors.dark.blue',
  'colors.dark.grey',
);

const renderOptionComponent = (
  props: Object,
  action: Function,
  setDefault: Function,
  handlePinOrUnpinReport: Function,
) => {
  const { data, selectOption } = props;

  const reportGuid = G.getGuidFromObject(data);
  const pinnedReport = G.getPropFromObject('pinnedReport', data);

  return (
    <ReportPanelOptionWrapper>
      <Box
        pr={10}
        width='calc(100% - 32px)'
        onClick={() => {
          selectOption(data);
          action(reportGuid);
        }}
      >
        {data.name}
      </Box>
      <Flex>
        {
          G.isFunction(handlePinOrUnpinReport) &&
          <Box
            mr='5px'
            onClick={() => {
              selectOption(data);
              handlePinOrUnpinReport({ reportGuid, pinnedReport });
            }}
          >
            {I.renderClipIcon(getIconColor(pinnedReport))}
          </Box>
        }
        <Box
          onClick={(e: Object) => {
            selectOption(data);
            action(reportGuid, true);
            setDefault(e, data);
          }}
        >
          {I.pinNew(getIconColor(data.defaultReport))}
        </Box>
      </Flex>
    </ReportPanelOptionWrapper>
  );
};

const renderValueComponent = ({ data }: Object) => (
  <TextComponent
    my={6}
    ml='8px'
    title={data.name}
    position='absolute'
    withEllipsis={true}
    width='calc(100% - 8px)'
  >
    {data.name}
  </TextComponent>
);

// TODO: check render select for different reports
const renderSelect = ({
  input,
  width,
  margin,
  options,
  version,
  placeholder,
  selectAction,
  selectReportText,
  setAsDefaultAction,
  handlePinOrUnpinReport,
}: Object) => (
  <RelativeBox>
    <AbsoluteBox bottom='calc(100% - 2px)'>
      <TextComponent
        ml={25}
        mb='5px'
        fontSize={9}
        color={G.getThemeByCond(G.isThemeSecondVersion(version), 'colors.light.black', 'colors.dark.grey')}
      >
        {R.or(selectReportText, G.getWindowLocale('titles:select-report', 'Select Report'))}
      </TextComponent>
    </AbsoluteBox>
    <ReactSelect
      height={30}
      name={input.name}
      isClearable={false}
      placeholder={placeholder}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      value={getValue(input, options)}
      options={renderOptions(options)}
      shouldNotGetValueFromOptions={true}
      additionalStyles={{
        container: (baseStyles: Object) => ({
          ...baseStyles,
          width: R.or(width, 232),
          margin: R.or(margin, '0 8px'),
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
        SingleValue: renderValueComponent,
        Option: (props: Object) => renderOptionComponent(
          props,
          selectAction,
          setAsDefaultAction,
          handlePinOrUnpinReport,
        ),
      }}
    />
  </RelativeBox>
);

const withHandleExportReport = withHandlers({
  handleExportReportFile: (props: Object) => () => {
    const { filterBy, selectedReport, exportReportDataRequest } = props;

    const fields = R.pathOr({}, ['fields'], selectedReport);
    const report = R.assoc('fields', G.addDisplayNameToReportFields(fields, filterBy), selectedReport);
    exportReportDataRequest({
      fileType: 'xlsx',
      ...report,
    });
  },
});

export const ExportReport = withHandleExportReport((props: Object) => {
  const { name, color, noPropagation, exportPermissions } = props;

  return (
    <AuthWrapper has={R.or(exportPermissions, null)}>
      <HoveringTitle
        title={G.getWindowLocale('titles:download-file', 'Download File ')}
        positionConfig={{
          zIndex: 14,
          right: '70%',
          bottom: '110%',
          width: 'max-content',
        }}
      >
        <ActionsDropdown
          type='click'
          position='right'
          noPropagation={noPropagation}
          guid={`user-export${R.or(name, '')}`}
          frontIcon={I.downloadDocument(color, 21, 24)}
        >
          {
            exportFileType(props).map((type: Object, i: number) => (
              <GenerateFile key={i} onClick={type.action}>
                <span>{I[type.label]()}</span>
                <span>{type.text}</span>
              </GenerateFile>
            ))
          }
        </ActionsDropdown>
      </HoveringTitle>
    </AuthWrapper>
  );
});

export const ReportFormatPanel = (props: Object) => {
  const {
    version,
    options,
    noEditReport,
    selectAction,
    noExportable,
    editReportText,
    editReportAction,
    noSelectDropdown,
    selectReportText,
    handlePinOrUnpinReport,
    handleSetDefaultAction,
  } = props;

  const color = G.getThemeByCond(
    G.isThemeSecondVersion(version),
    'colors.dark.blue',
    'icons.iconColor',
  );

  return (
    <ReportWrapper>
      {
        R.not(noEditReport) &&
        <AuthWrapper has={[PC.REPORT_WRITE]}>
          <Box
            color={color}
            fontSize={13}
            cursor='pointer'
            textWrap='nowrap'
            onClick={editReportAction}
          >
            {R.or(editReportText, G.getWindowLocale('titles:edit-report', 'Edit Report'))}
          </Box>
        </AuthWrapper>
      }
      {
        R.not(noSelectDropdown) &&
        <Field
          width={180}
          version={version}
          name='report-panel'
          component={renderSelect}
          options={R.or(options, [])}
          selectAction={selectAction}
          selectReportText={selectReportText}
          setAsDefaultAction={handleSetDefaultAction}
          handlePinOrUnpinReport={handlePinOrUnpinReport}
        />
      }
      {
        R.not(noExportable) &&
        <ExportReport {...props} />
      }
    </ReportWrapper>
  );
};

export default enhance(ReportFormatPanel);
