import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState } from 'react-recompose';
// components
import { PopperComponent } from '../popper';
import { AddressBlock } from '../address-block';
// features
import { AuthWrapper } from '../../features/permission';
import PC from '../../features/permission/role-permission';
import BusinessHours from '../../features/business-hours';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { ENUMS } from '../../constants/enums';
// forms
import withStopForm from '../../forms/hocs/with-stop-form';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, StyledLink, HideIfExpandedContainerOpenedFromPageBox } from '../../ui';
// components events
import { withStopsEditAndDnD } from './hocs';
/////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');
const darkBlueColor = G.getTheme('colors.dark.darkBlue');
const defaultEventColor = G.getTheme('colors.greyMatterhorn');

const titles = {
  comments: G.getWindowLocale('titles:comments', 'Comments'),
  contact: G.getWindowLocale('titles:contact-name', 'Contact Name'),
  dropNumber: G.getWindowLocale('titles:drop-number', 'Drop Number'),
  integration: G.getWindowLocale('titles:integration', 'Integration'),
  [GC.FIELD_ARRIVE_DATE]: G.getWindowLocale('titles:arrived', 'Arrived'),
  pickupNumber: G.getWindowLocale('titles:pickup-number', 'Pickup Number'),
  [GC.FIELD_ESTIMATED_TIME_OF_ARRIVAL]: G.getWindowLocale('titles:eta', 'ETA'),
  [GC.FIELD_EVENT_COMPLETE_DATE]: G.getWindowLocale('titles:completed', 'Completed'),
  [GC.FIELD_EVENT_CHECK_IN_DATE]: G.getWindowLocale('titles:checked-in', 'Checked In'),
  distanceToNextStop: G.getWindowLocale('titles:distance-to-next-stop', 'Distance to next stop'),
  [GC.FIELD_LOAD_APPOINTMENT_NUMBER]: G.getWindowLocale('titles:appointment-number', 'Appointment-number'),
  [GC.EVENT_TYPE_DROP]: {
    [GC.FIELD_LOAD_EVENT_LATE_DATE]: G.getWindowLocale('titles:drop-range-late', 'Drop Range Late'),
    [GC.FIELD_LOAD_EVENT_EARLY_DATE]: G.getWindowLocale('titles:drop-range-early', 'Drop Range Early'),
  },
  [GC.EVENT_TYPE_PICKUP]: {
    [GC.FIELD_LOAD_EVENT_LATE_DATE]: G.getWindowLocale('titles:pickup-range-late', 'Pickup Range Late:'),
    [GC.FIELD_LOAD_EVENT_EARLY_DATE]: G.getWindowLocale('titles:pickup-range-early', 'Pickup Range Early:'),
  },
};

const InfoPair = ({ text, title }: Object) => (
  <Box
    py='3px'
    width='100%'
    wordBreak='break-all'
    color={defaultEventColor}
  >
    {title}: {text}
  </Box>
);

const renderDistance = (loadType: string, stop: Object) => {
  const distanceObject = R.prop(G.getDistancePropByLoadType(loadType), stop);
  let distanceText = '';

  if (R.isNil(distanceObject)) {
    distanceText = G.getWindowLocale('titles:end-of-trip', 'End of Trip');
  } else {
    const { totalTripDistance, totalTripDistanceUom } = G.calculateTotalDistance(R.of(Array, distanceObject));
    distanceText = `${totalTripDistance} ${totalTripDistanceUom}`;
  }

  return distanceText;
};

const getColorByEventStatus = (status: string) => G.getTheme(`status.${status}`, defaultEventColor);

const editEventsPermissionsMap = {
  [GC.LOAD_TYPE_CLO]: PC.CLO_WRITE,
  [GC.LOAD_TYPE_TEL]: PC.TEL_WRITE,
};

const relatedLoadReferencePermissionsMap = {
  [GC.LOAD_TYPE_CLO]: PC.TEL_WRITE,
  [GC.LOAD_TYPE_TEL]: PC.CLO_WRITE,
};

const stopStatusesForComplete = [
  GC.STOP_STATUS_TYPE_PENDING,
  GC.STOP_STATUS_TYPE_ARRIVED,
  GC.STOP_STATUS_TYPE_CHECKED_IN,
];

const loadStatusesForComplete = [
  GC.LOAD_STATUS_IN_TRANSIT,
  GC.LOAD_STATUS_BOOKED_STATUS,
];

const actionBtnsProps = {
  ml: 10,
  mb: '8px',
  p: '2px 4px',
  fontSize: 12,
  cursor: 'pointer',
  borderRadius: '5px',
  color: darkBlueColor,
  border: `1px solid ${darkBlueColor}`,
};

const EventStatuses = ({
  events,
  branchGuid,
  loadStatus,
  isLoadTypeTel,
  referenceTypes,
  handleChangeStop,
  handleAddMultipleStatusMessage,
  allowStopCompleteWithoutArrival,
}: Object) => (
  <Flex>
    {
      events.map((event: Object, index: number) => {
        const { status } = event;

        const nextEventStatus = R.path([R.inc(index), GC.FIELD_STATUS], events);
        const permissions = R.of(Array, G.getPropFromObject(event.loadType, editEventsPermissionsMap));
        const stopStatusesForCompleteToUse = G.ifElse(
          R.and(
            G.isFalse(allowStopCompleteWithoutArrival),
            G.notEquals(status, GC.STOP_STATUS_TYPE_CHECKED_IN),
          ),
          R.of(Array, GC.STOP_STATUS_TYPE_CHECKED_IN),
          stopStatusesForComplete,
        );

        const allowComplete = G.isAllTrue(
          isLoadTypeTel,
          R.includes(status, stopStatusesForCompleteToUse),
          R.includes(loadStatus, loadStatusesForComplete),
        );

        const allowCheckIn = G.isAllTrue(
          isLoadTypeTel,
          R.includes(status, stopStatusesForComplete),
          R.includes(loadStatus, loadStatusesForComplete),
        );

        return (
          <Box key={index}>
            <Flex>
              <Box
                mr='5px'
                mb='8px'
                fontSize={14}
                fontWeight='bold'
                color={getColorByEventStatus(status)}
              >
                {G.getWindowLocale(R.prop(status, GC.statusLocaleMap))}
              </Box>
              {
                G.isFunction(handleChangeStop) &&
                <AuthWrapper has={permissions}>
                  <HideIfExpandedContainerOpenedFromPageBox
                    cursor='pointer'
                    openedFromPage={GC.ROUTE_PATH_CARRIER_INVOICES_LIST}
                    onClick={() => handleChangeStop({ event, branchGuid, referenceTypes })}
                  >
                    {I.pencil(blueColor)}
                  </HideIfExpandedContainerOpenedFromPageBox>
                </AuthWrapper>
              }
              {
                allowCheckIn &&
                <Box
                  {...actionBtnsProps}
                  onClick={() => handleAddMultipleStatusMessage(event, true)}
                >
                  {G.getWindowLocale('titles:arrived', 'Arrived')}
                </Box>
              }
              {
                allowComplete &&
                <Box
                  {...actionBtnsProps}
                  onClick={() => handleAddMultipleStatusMessage(event)}
                >
                  {G.getWindowLocale('titles:completed', 'Completed')}
                </Box>
              }
            </Flex>
            <Flex>
              <Flex
                width={25}
                height={25}
                fontSize={11}
                borderRadius='50%'
                justifyContent='center'
                bg={R.prop('headerBg', G.renderStopColor(event))}
                color={G.getTheme(G.ifElse(G.isStopDrop(event), 'colors.white', 'colors.light.black'))}
              >
                {
                  G.ifElse(
                    G.isLoadTypeClo(event),
                    '',
                    G.ifElse(G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, event)), 'T', 'I'), '')
                }{
                  R.pathOr('', ['eventType', 0], event)
                }{R.prop(G.ifElse(isLoadTypeTel, GC.FIELD_TEL_EVENT_INDEX, GC.FIELD_CLO_EVENT_INDEX), event)}
              </Flex>
              {
                G.notEquals(R.inc(index), R.length(events)) &&
                <Flex
                  width={250}
                  height='4px'
                  background={`linear-gradient(
                    90deg, ${getColorByEventStatus(status)} 10%, ${getColorByEventStatus(nextEventStatus)} 100%
                  )`}
                />
              }
            </Flex>
          </Box>
        );
      })
    }
  </Flex>
);

const ItemTypeVehicleInfo = (props: Object) => {
  const { vin, weight, weightType, singleItem } = props;

  const weightWithWeightType = `${weight} ${weightType}`;
  const item = R.assoc('weightWithWeightType', weightWithWeightType, props);

  const Component = ({ useVin }: Object) => (
    <Flex py='4px' wordBreak='break-all'>
      {G.isFalse(useVin) && <Box mr='4px'>{I.itemBox()}</Box>}
      {
        R.and(G.isNotNilAndNotEmpty(vin), G.isTrue(useVin)) &&
        <Flex mr='4px'>
          {I.vin(blueColor, 16, 16)}
          <Box ml='4px'>{vin}</Box>
        </Flex>
      }
      {G.filterAndJoinObjectFields(
        item,
        [GC.FIELD_YEAR, GC.FIELD_MAKE, GC.FIELD_MODEL, GC.FIELD_COLOR, 'weightWithWeightType'],
      )}
    </Flex>
  );

  if (G.isTrue(singleItem)) {
    return (
      <PopperComponent
        type='hover'
        content={<Box p='2px 8px'><Component useVin={true} /></Box>}
      >
        <Component useVin={false} />
      </PopperComponent>
    );
  }

  return <Component useVin={G.isFalse(singleItem)} />;
};

export const ItemInfo = (props: Object) => {
  const {
    itemId,
    weight,
    quantity,
    maxWidth,
    hazardous,
    weightType,
    singleItem,
    packageType,
    temperatureLow,
    temperatureUOM,
    temperatureHigh,
    temperatureSensor,
  } = props;

  const isFahrenheit = R.equals(GC.UOM_FAHRENHEIT, temperatureUOM);
  const temperatureSymbol = G.ifElse(isFahrenheit, 'F', 'C');

  const temperatureLowIcon = G.ifElse(
    R.lte(temperatureLow, G.ifElse(isFahrenheit, 32, 0)),
    I.renderColdIcon,
    I.renderHotIcon,
  );

  const temperatureHighIcon = G.ifElse(
    R.lte(temperatureHigh, G.ifElse(isFahrenheit, 32, 0)),
    I.renderColdIcon,
    I.renderHotIcon,
  );

  if (G.isItemTypeVehicle(props)) {
    return <ItemTypeVehicleInfo {...props} singleItem={singleItem} />;
  }

  return (
    <Flex py='4px' flexWrap='wrap' wordBreak='break-all'>
      {I.itemBox()}
      <Box ml='4px'>{itemId},</Box>
      {
        hazardous &&
        <Box ml='4px'>{I.renderHazardousIcon()}</Box>
      }
      {
        R.equals(temperatureSensor, ENUMS.ENUM_YES) &&
        <Box ml='4px' title={G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor')}>
          {I.temperatureSensor()}
        </Box>
      }
      {
        G.isNotNil(temperatureLow) &&
        <Flex ml='4px'>
          <Box mr='4px'>{temperatureLow}{temperatureSymbol}</Box>
          {temperatureLowIcon()},
        </Flex>
      }
      {
        G.isNotNil(temperatureHigh) &&
        <Flex ml='4px'>
          <Box mr='4px'>{temperatureHigh}{temperatureSymbol}</Box>
          {temperatureHighIcon()},
        </Flex>
      }
      <Box ml='4px' maxWidth={R.or(maxWidth, 'calc(100% - 40px)')}>{weight} {weightType}, {quantity} {packageType}</Box>
    </Flex>
  );
};

const ItemsInfo = (props: Object) => {
  const {
    items,
    totalWeight,
    anyHazardous,
    totalQuantity,
    temperatureLow,
    temperatureHigh,
    hasTemperatureSensor,
  } = props;

  const popperContent = (
    <Box p='2px 8px'>
      {
        items.map((item: Object, i: number) => (
          <ItemInfo key={i} {...item} singleItem={false} />
        ))
      }
    </Box>
  );

  return (
    <PopperComponent
      type='hover'
      content={popperContent}
    >
      <Flex pt='4px' pb='8px'>
        {I.itemBox()}
        {
          anyHazardous &&
          <Box ml='4px'>{I.renderHazardousIcon()}</Box>
        }
        {
          hasTemperatureSensor &&
          <Box ml='4px' title={G.getWindowLocale('titles:temperature-sensor', 'Temperature Sensor')}>
            {I.temperatureSensor()}
          </Box>
        }
        {
          temperatureLow &&
          <Box ml='4px'>{I.renderColdIcon()}</Box>
        }
        {
          temperatureHigh &&
          <Box ml='4px'>{I.renderHotIcon()}</Box>
        }
        <Box ml='4px'>{totalWeight.weight} {totalWeight.weightType}, {totalQuantity}</Box>
      </Flex>
    </PopperComponent>
  );
};

export const ContainerInfo = (props: Object) => {
  const { labelStart, weightUom, containerNumber, containerInitial, fullContainerWeight } = props;

  const label = `${labelStart} ${G.getWindowLocale('titles:container', 'Container')}:`;
  const info1 = `${G.createStringFromArray([containerInitial, containerNumber], '')}, `;
  const info2 = G.createStringFromArray([fullContainerWeight, weightUom], ' - ');

  return (
    <Flex py='4px' wordBreak='break-all'>
      {I.itemBox()}
      <Box ml='4px' maxWidth='calc(100% - 20px)'>{label} {info1} {info2}</Box>
    </Flex>
  );
};

const ContainersInfo = (props: Object) => {
  const { event } = props;
  const { droppedContainers, pickedUpContainers } = event;

  if (R.and(G.isNilOrEmpty(droppedContainers), G.isNilOrEmpty(pickedUpContainers))) return null;

  return (
    <div>
      {
        G.isNotNilAndNotEmpty(droppedContainers) &&
        <Box py='2px'>
          {droppedContainers.map((item: Object, i: number) => <ContainerInfo key={i} {...item} labelStart={'D.'} />)}
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(pickedUpContainers) &&
        <Box py='2px'>
          {pickedUpContainers.map((item: Object, i: number) => <ContainerInfo key={i} {...item} labelStart={'P.'} />)}
        </Box>
      }
    </div>
  );
};

export const TrailerInfo = (props: Object) => {
  const { unitId, labelStart } = props;

  const label = `${labelStart} ${G.getWindowLocale('titles:trailer', 'Trailer')}:`;

  return (
    <Flex py='4px' wordBreak='break-all'>
      {I.trailer()}
      <Box ml='4px' maxWidth='calc(100% - 20px)'>{label} {unitId}</Box>
    </Flex>
  );
};

const TrailersInfo = (props: Object) => {
  const { event } = props;
  const { droppedTrailers, pickedUpTrailers } = event;

  if (R.and(G.isNilOrEmpty(droppedTrailers), G.isNilOrEmpty(pickedUpTrailers))) return null;

  return (
    <div>
      {
        G.isNotNilAndNotEmpty(droppedTrailers) &&
        <Box py='2px'>
          {droppedTrailers.map((item: Object, i: number) => <TrailerInfo key={i} {...item} labelStart={'D.'} />)}
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(pickedUpTrailers) &&
        <Box py='2px'>
          {pickedUpTrailers.map((item: Object, i: number) => <TrailerInfo key={i} {...item} labelStart={'P.'} />)}
        </Box>
      }
    </div>
  );
};

const getPhoneNumber = (phone: string, phoneExtension: string) => G.ifElse(
  G.isNotNilAndNotEmpty(phoneExtension),
  `${phone}${phoneExtension}`,
  phone,
);

const Contact = ({ email, phone, autodialApp, contactName, phoneExtension }: Object) => (
  <Box py='4px'>
    {
      G.isNotNilAndNotEmpty(contactName) &&
      <Box wordBreak='break-all'>
        {R.prop('contact', titles)}: {contactName}
      </Box>
    }
    <Flex width='100%' flexWrap='wrap'>
      {
        G.isNotNilAndNotEmpty(phone) &&
        <StyledLink
          mr={20}
          pt='4px'
          fontSize={11}
          display='flex'
          color={blueColor}
          wordBreak='break-all'
          href={G.getCallUrl(autodialApp, getPhoneNumber(phone, phoneExtension))}
        >
          <Box mr='4px'>{I.phone(blueColor, 10, 10)}</Box>
          {phone}{G.isNotNilAndNotEmpty(phoneExtension) && `(${phoneExtension})`}
        </StyledLink>
      }
      {
        G.isNotNilAndNotEmpty(email) &&
        <StyledLink
          pt='4px'
          fontSize={11}
          display='flex'
          color={blueColor}
          wordBreak='break-all'
          alignItems='flex-start'
          href={`mailto:${email}`}
          textDecoration='underline'
        >
          <Box mt='2px' mr='4px'>{I.renderMailIcon(blueColor, 12, 8)}</Box>
          {email}
        </StyledLink>
      }
    </Flex>
  </Box>
);

const enhanceExpanded = compose(
  withState('expanded', 'toggle', false),
  pure,
);

const Contacts = enhanceExpanded(({ toggle, contacts, expanded, autodialApp }: Object) => {
  const contactsToUse = R.filter(
    R.compose(
      G.isOneNotNilOrNotEmpty,
      R.values,
      R.pick([GC.FIELD_PHONE, GC.FIELD_EMAIL, GC.FIELD_CONTACT_NAME]),
    ),
    R.or(contacts, []),
  );

  if (R.isEmpty(contactsToUse)) return null;

  if (R.equals(R.length(contactsToUse), 1)) return <Contact {...R.head(contactsToUse)} autodialApp={autodialApp} />;

  return (
    <Box color={defaultEventColor}>
      <Flex cursor='pointer' onClick={() => toggle(R.not(expanded))}>
        {G.getWindowLocale('titles:contacts', 'Contacts')}
        <Box mx='6px'>({R.length(contactsToUse)})</Box>
        {
          G.ifElse(expanded, I.arrowUpSimple, I.arrowDownSimple)(defaultEventColor)
        }
      </Flex>
      {
        expanded &&
        contactsToUse.map((item: Object, index: number) => <Contact {...item} key={index} autodialApp={autodialApp} />)
      }
    </Box>
  );
});

const References = enhanceExpanded(({ toggle, expanded, references }: Object) => {
  if (R.equals(R.length(references), 1)) {
    return (
      <Flex width='100%' flexWrap='wrap' color={defaultEventColor}>
        <Box mr='5px' fontWeight='bold'>
          {R.path([0, GC.FIELD_NAME], references)}:
        </Box>
        <Box wordBreak='break-word'>
          {R.path([0, GC.FIELD_VALUE], references)}
        </Box>
      </Flex>
    );
  }

  return (
    <Box color={defaultEventColor}>
      <Flex cursor='pointer' onClick={() => toggle(R.not(expanded))}>
        {G.getWindowLocale('titles:references', 'References')}
        <Box mx='6px'>({R.length(references)})</Box>
        {
          G.ifElse(expanded, I.arrowUpSimple, I.arrowDownSimple)(defaultEventColor)
        }
      </Flex>
      {
        expanded &&
        references.map((ref: Object, i: number) => (
          <Flex key={i} my='2px' flexWrap='wrap'>
            <Box mr='5px' fontWeight='bold'>
              {R.prop(GC.FIELD_NAME, ref)}:
            </Box>
            <Box wordBreak='break-word'>
              {R.prop(GC.FIELD_VALUE, ref)}
            </Box>
          </Flex>
        ))
      }
    </Box>
  );
});

const EventsComponent = ({
  loads,
  events,
  loadType,
  autodialApp,
  handleChangeAppointment,
  handleClickOnPrimaryReference,
}: Object) => (
  <Flex width='fit-content' alignItems='flex-start'>
    {
      events.map((event: Object, index: number) => {
        const {
          items,
          cloGuid,
          telGuid,
          location,
          eventType,
          references,
          stopNumber,
          arriveDate,
          eventLateDate,
          eventEarlyDate,
          eventCheckinDate,
          eventCompleteDate,
          appointmentNumber,
          estimatedTimeOfArrival,
        } = event;

        const { contacts, comments, operationHour } = location;

        const manualDistance = R.path([G.getDistancePropByLoadType(GC.FIELD_TEL), 'manualDistance'], event);
        const marginRight = G.ifElse(R.equals(index, R.subtract(R.length(events), 1)), 0, 25);
        const itemsInfo = G.getItemsTotals(items);

        const { itemsTotal } = itemsInfo;

        const isFromClo = G.isLoadTypeClo(loadType);
        const loadGuid = G.ifElse(isFromClo, telGuid, cloGuid);

        const load = R.compose(
          R.prop(loadGuid),
          R.indexBy(R.prop(GC.FIELD_GUID)),
        )(R.or(loads, []));

        const loadLabel = G.ifElse(
          isFromClo,
          G.getWindowLocale('titles:tel', 'Trip'),
          G.getWindowLocale('titles:clo', 'Order'),
        );

        const hasCurrentUserRelatedLoadPermission = G.hasAmousCurrentUserPermissions(
          G.getPropFromObject(loadType, relatedLoadReferencePermissionsMap),
        );

        const integration = G.createEventIntegrationString(event);

        return (
          <Box key={index} width={250} fontSize={11} mr={marginRight}>
            <ContainersInfo event={event} />
            <TrailersInfo event={event} />
            {
              R.gt(itemsTotal, 1) &&
              <ItemsInfo {...itemsInfo} items={items} />
            }
            {
              R.equals(itemsTotal, 1) && <ItemInfo {...R.head(items)} singleItem={true} />
            }
            {
              G.isNotNilAndNotEmpty(references) &&
              <References references={references} />
            }
            {
              G.isNotNil(load) &&
              <Flex py='3px' width='100%' flexWrap='wrap'>
                <Box mr='4px' color={defaultEventColor}>{loadLabel}:</Box>
                {
                  hasCurrentUserRelatedLoadPermission &&
                  <Box
                    cursor='pointer'
                    color={blueColor}
                    fontWeight='bold'
                    wordBreak='break-word'
                    textDecoration='underline'
                    onClick={() => handleClickOnPrimaryReference(loadGuid, isFromClo)}
                  >
                    {R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE], load)}
                  </Box>
                }
                {
                  R.not(hasCurrentUserRelatedLoadPermission) &&
                  <Box fontWeight='bold'>
                    {R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE], load)}
                  </Box>
                }
              </Flex>
            }
            {
              G.isFunction(handleChangeAppointment) &&
              <Box
                mr={10}
                cursor='pointer'
                color={blueColor}
                onClick={() => handleChangeAppointment(event)}
              >
                {G.getWindowLocale('titles:change-appointment', 'Change Appointments')}
              </Box>
            }
            {
              G.isNotNil(arriveDate) &&
              <InfoPair
                text={G.getDateTimeByConfigFormat(arriveDate)}
                title={R.path([GC.FIELD_ARRIVE_DATE], titles)}
              />
            }
            {
              G.isNotNil(eventCheckinDate) &&
              <InfoPair
                text={G.getDateTimeByConfigFormat(eventCheckinDate)}
                title={R.path([GC.FIELD_EVENT_CHECK_IN_DATE], titles)}
              />
            }
            {
              G.isNotNil(eventCompleteDate) &&
              <InfoPair
                text={G.getDateTimeByConfigFormat(eventCompleteDate)}
                title={R.path([GC.FIELD_EVENT_COMPLETE_DATE], titles)}
              />
            }
            <InfoPair
              text={appointmentNumber}
              title={R.prop(GC.FIELD_LOAD_APPOINTMENT_NUMBER, titles)}
            />
            <InfoPair
              text={G.getDateTimeByConfigFormat(eventEarlyDate)}
              title={R.path([eventType, GC.FIELD_LOAD_EVENT_EARLY_DATE], titles)}
            />
            <InfoPair
              text={G.getDateTimeByConfigFormat(eventLateDate)}
              title={R.path([eventType, GC.FIELD_LOAD_EVENT_LATE_DATE], titles)}
            />
            {
              G.isNotNilAndNotEmpty(estimatedTimeOfArrival) &&
              <InfoPair
                text={G.getDateTimeByConfigFormat(estimatedTimeOfArrival)}
                title={R.prop(GC.FIELD_ESTIMATED_TIME_OF_ARRIVAL, titles)}
              />
            }
            {
              G.isNotNilAndNotEmpty(stopNumber) &&
              <InfoPair
                text={stopNumber}
                title={R.prop(G.ifElse(G.isStopPickup(event), 'pickupNumber', 'dropNumber'), titles)}
              />
            }
            <InfoPair
              title={R.prop('distanceToNextStop', titles)}
              text={renderDistance(R.toLower(loadType), event, manualDistance)}
            />
            { integration &&
              <InfoPair
                text={integration}
                title={R.prop('integration', titles)}
              />
            }
            <Box py='4px' color={defaultEventColor}>
              <AddressBlock width={250} fontSize={11} location={location} />
              {
                G.isNotNilAndNotEmpty(comments) &&
                <Box my='3px' maxHeight={60} overflow='auto' wordBreak='break-all'>
                  {R.prop('comments', titles)}: {comments}
                </Box>
              }
              <Contacts contacts={contacts} autodialApp={autodialApp} />
              {
                G.isNotNilAndNotEmpty(operationHour) &&
                <Box fontWeight='bold'>
                  {G.getWindowLocale('titles:business-hours', 'Business Hours')}:
                </Box>
              }
              <BusinessHours operationHour={operationHour} componentType='eventSimple' />
            </Box>
          </Box>
        );
      })
    }
  </Flex>
);

const enhance = compose(
  withStopsEditAndDnD,
  withStopForm,
  pure,
);

const EditStopsBtn = ({ events, handleClickEditStops }: Object) => (
  <HideIfExpandedContainerOpenedFromPageBox
    mr={10}
    p='5px'
    mb='5px'
    fontSize={11}
    cursor='pointer'
    borderRadius='5px'
    color={darkBlueColor}
    display='inline-block'
    border={`1px solid ${darkBlueColor}`}
    onClick={() => handleClickEditStops(events)}
    openedFromPage={GC.ROUTE_PATH_CARRIER_INVOICES_LIST}
  >
    {G.getWindowLocale('titles:edit-events', 'Edit Events')}
  </HideIfExpandedContainerOpenedFromPageBox>
);

const checkTerminalsInEvents = (events: Array) => R.compose(
  R.equals(0),
  R.length,
  R.filter(R.pathEq(GC.STOP_TYPE_TERMINAL, [GC.FIELD_STOP_TYPE])),
)(events);

const checkEventsTel = (events: Array) => R.compose(
  R.equals(1),
  R.length,
  R.uniq,
  R.map(R.prop(GC.FIELD_TEL_GUID)),
)(events);

export const DetailsEvents = enhance((props: Object) => {
  const {
    loads,
    events,
    loadType,
    branchGuid,
    loadStatus,
    loadConfigs,
    autodialApp,
    referenceTypes,
    handleChangeStop,
    handleClickEditStops,
    handleClickOnPrimaryReference,
    handleAddMultipleStatusMessage,
  } = props;

  const allowStopCompleteWithoutArrival = G.getConfigValueFromStore(
    GC.TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL,
    G.getPropFromObject('configsByNames', loadConfigs),
  );

  return (
    <Box width='100%'>
      <Box mx='auto' p='8px 25px' overflow='auto' maxWidth={1550}>
        {
          G.isLoadTypeClo(loadType) &&
          G.hasAmousCurrentUserPermissions(PC.CLO_WRITE) &&
          G.isFunction(handleClickEditStops) &&
          checkTerminalsInEvents(events) &&
          <EditStopsBtn events={events} handleClickEditStops={handleClickEditStops} />
        }
        <EventStatuses
          events={events}
          branchGuid={branchGuid}
          loadStatus={loadStatus}
          loadConfigs={loadConfigs}
          autodialApp={autodialApp}
          referenceTypes={referenceTypes}
          handleChangeStop={handleChangeStop}
          isLoadTypeTel={G.isLoadTypeTel(R.or(loadType, ''))}
          handleAddMultipleStatusMessage={handleAddMultipleStatusMessage}
          allowStopCompleteWithoutArrival={allowStopCompleteWithoutArrival}
        />
        <EventsComponent
          loads={loads}
          events={events}
          loadType={loadType}
          handleClickOnPrimaryReference={handleClickOnPrimaryReference}
        />
      </Box>
    </Box>
  );
});

const Events = (props: Object) => {
  const {
    loads,
    events,
    loadType,
    branchGuid,
    loadStatus,
    autodialApp,
    asyncConfigs,
    referenceTypes,
    handleChangeStop,
    handleClickEditStops,
    handleChangeAppointment,
    handleClickOnPrimaryReference,
    handleAddMultipleStatusMessage,
  } = props;

  const allowStopCompleteWithoutArrival = G.getConfigValueFromStore(
    GC.TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL,
    asyncConfigs,
  );

  return (
    <Box width='fit-content'>
      {
        checkEventsTel(events) &&
        G.isLoadTypeClo(loadType) &&
        checkTerminalsInEvents(events) &&
        G.isFunction(handleClickEditStops) &&
        G.hasAmousCurrentUserPermissions(PC.CLO_WRITE) &&
        <EditStopsBtn events={events} handleClickEditStops={handleClickEditStops} />
      }
      <EventStatuses
        events={events}
        loadType={loadType}
        loadStatus={loadStatus}
        branchGuid={branchGuid}
        referenceTypes={referenceTypes}
        handleChangeStop={handleChangeStop}
        handleAddMultipleStatusMessage={handleAddMultipleStatusMessage}
        allowStopCompleteWithoutArrival={allowStopCompleteWithoutArrival}
      />
      <EventsComponent
        loads={loads}
        events={events}
        loadType={loadType}
        autodialApp={autodialApp}
        handleChangeAppointment={handleChangeAppointment}
        handleClickOnPrimaryReference={handleClickOnPrimaryReference}
      />
    </Box>
  );
};

export default enhance(Events);
