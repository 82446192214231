import * as R from 'ramda';
import React from 'react';
// components
import { Label } from '../label';
import { fieldNameToTransform } from '../filter/settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
//////////////////////////////////////////////////

export const getValue = (input: Object, options: Array) => (
  R.find((option: Object) => (
      R.or(
        R.equals(option.name, input.value),
        R.equals(option.value, input.value),
      )),
    options,
  )
);

export const buttons = (handleUseAction: Function) => ([
  {
    width: 'auto',
    type: 'button',
    action: handleUseAction,
    btnText: (
      <Label frontIcon={I.play(G.getTheme('icons.iconColorWhite'))} >
        {G.getWindowLocale('actions:use', 'Use')}
      </Label>
    ),
  },
]);

export const mapFieldsWithSequenceFromIndex = (fields: Array) => G.mapIndexed(
  (field: Object, index: number) => R.assoc('sequence', R.inc(index), field),
  fields,
);

export const buildPivotReport = (props: Object, columnSettings: Object, isCreate: any) => {
  const { pivotState, selectedReport } = props;

  const { fields, searchCriteria } = selectedReport;

  const localeMap = G.localeKeyToReportFieldMap(columnSettings);

  const rows = R.pathOr([], ['rows'], pivotState);
  const columns = R.pathOr([], ['cols'], pivotState);
  const aggregatedValues = R.pathOr([], ['vals'], pivotState);
  const renderType = R.pathOr('Table', ['rendererName'], pivotState);
  const aggregation = R.pathOr('Count', ['aggregatorName'], pivotState);

  if (G.isTrue(isCreate)) {
    return R.omit(['orderFields'], {
      ...selectedReport,
      guid: null,
      version: null,
      renderType,
      aggregation,
      aggregatedValues,
      fields: R.map(R.omit([GC.FIELD_GUID]), fields),
      searchCriteria: R.map(R.omit([GC.FIELD_GUID]), searchCriteria),
      rows: rows.map((item: string) => R.path([item, 'key'], localeMap)),
      columns: columns.map((item: string) => R.path([item, 'key'], localeMap)),
    });
  }

  return R.omit(['orderFields'], {
    ...selectedReport,
    renderType,
    aggregation,
    aggregatedValues,
    rows: rows.map((item: string) => R.path([item, 'key'], localeMap)),
    columns: columns.map((item: string) => R.path([item, 'key'], localeMap)),
  });
};

export const buildPivotComplexReport = (props: Object, columnSettings: Object, isCreate: any) => {
  const { selectedReport } = props;

  const complexReport = flexMonsterPivotInstance.getReport(); // eslint-disable-line
  const pivotSettings = R.omit(['dataSource'], complexReport);

  if (G.isTrue(isCreate)) {
    const reportToUse = G.omitObjectChildArrayItemSystemFields(
      'fields',
      G.omitObjectSystemFields(selectedReport),
    );

    return R.omit(['orderFields'], {
      ...reportToUse,
      pivotSettings: JSON.stringify(pivotSettings),
    });
  }

  return R.omit(['orderFields'], {
    ...selectedReport,
    pivotSettings: JSON.stringify(pivotSettings),
  });
};

const createSelectMultipleValue = (item: Object) => {
  if (G.isNilOrEmpty(R.path(['selectMultipleValue'], item))) return '';
  return R.join(',', R.map(R.prop('value'), R.path(['selectMultipleValue'], item)));
};

const checkDataTypeInSearchCriteriaObject = (item: Object) => R.compose(
  R.set(R.lensProp('dataType'), 'string'),
  R.assoc('stringValue', createSelectMultipleValue(item)),
  R.omit(['selectMultipleValue']),
)(item);

const transformSearchItemByUomSystem = (item: Object, reportType: string) => {
  const shouldNotTransform = R.includes(
    reportType,
    [
      GC.ITEM_REPORT,
      GC.CONTAINER_TEMPLATE_REPORT,
      GC.COMPENSATION_TEMPLATE_REPORT,
      GC.FLEET_DRIVER_COMPENSATION_REPORT,
      GC.FLEET_VENDOR_COMPENSATION_REPORT,
    ],
  );

  if (shouldNotTransform) return item;

  const { operation, numberValue, numberRange, propertyName } = item;

  const isImperial = G.isImperialUomSystem();

  if (R.includes('distance', R.toLower(propertyName))) {
    if (R.and(isImperial, R.equals(operation, 'range'))) {
      const { to, from } = numberRange;

      const newNumberRange = {
        to: G.fromMilesToKmsNumber(to, 5),
        from: G.fromMilesToKmsNumber(from, 5),
      };

      return R.assoc('numberRange', newNumberRange, item);
    } else if (R.equals(operation, 'equal')) {
      const toValue = G.ifElse(
        isImperial,
        G.fromMilesToKmsNumber(R.add(numberValue, 1), 5),
        R.add(numberValue, 1),
      );

      const fromValue = G.ifElse(
        isImperial,
        G.fromMilesToKmsNumber(R.subtract(numberValue, 1), 5),
        R.subtract(numberValue, 1),
      );

      return {
        ...item,
        numberValue: null,
        operation: 'range',
        numberRange: {
          to: toValue,
          from: fromValue,
        },
      };
    }

    return R.assoc('numberValue', G.fromMilesToKmsNumber(numberValue, 5), item);
  }

  if (R.includes('weight', R.toLower(propertyName))) {
    if (R.and(isImperial, R.equals(operation, 'range'))) {
      const { to, from } = numberRange;

      const newNumberRange = {
        to: G.fromPoundsToKgs(to, 5),
        from: G.fromPoundsToKgs(from, 5),
      };

      return R.assoc('numberRange', newNumberRange, item);
    } else if (R.equals(operation, 'equal')) {
      const toValue = G.ifElse(
        isImperial,
        G.fromPoundsToKgs(R.add(numberValue, 1), 5),
        R.add(numberValue, 1),
      );

      const fromValue = G.ifElse(
        isImperial,
        G.fromPoundsToKgs(R.subtract(numberValue, 1), 5),
        R.subtract(numberValue, 1),
      );

      return {
        ...item,
        numberValue: null,
        operation: 'range',
        numberRange: {
          to: toValue,
          from: fromValue,
        },
      };
    }

    return R.assoc('numberValue', G.fromPoundsToKgs(numberValue, 5), item);
  }

  if (R.includes('volume', R.toLower(propertyName))) {
    if (R.and(isImperial, R.equals(operation, 'range'))) {
      const { to, from } = numberRange;

      const newNumberRange = {
        to: G.fromCubicFeetToMeter(to, 5),
        from: G.fromCubicFeetToMeter(from, 5),
      };

      return R.assoc('numberRange', newNumberRange, item);
    } else if (R.equals(operation, 'equal')) {
      const toValue = G.ifElse(
        isImperial,
        G.fromCubicFeetToMeter(R.add(numberValue, 1), 5),
        R.add(numberValue, 1),
      );

      const fromValue = G.ifElse(
        isImperial,
        G.fromCubicFeetToMeter(R.subtract(numberValue, 1), 5),
        R.subtract(numberValue, 1),
      );

      return {
        ...item,
        numberValue: null,
        operation: 'range',
        numberRange: {
          to: toValue,
          from: fromValue,
        },
      };
    }

    return R.assoc('numberValue', G.fromCubicFeetToMeter(numberValue, 5), item);
  }

  return item;
};

export const transformSearchCriteriaBeforeReportPost = (data: Array, reportType: string) => R.compose(
  R.map((item: Object) => transformSearchItemByUomSystem(item, reportType)),
  R.map((item: Object) => {
    const { dataType, propertyName, referenceName, referenceFieldName } = item;

    if (R.and(R.equals(propertyName, GC.FIELD_REFERENCES), G.isNotNilAndNotEmpty(referenceFieldName))) {
      const referencePrefix = `Ref (${G.getWindowLocale(...GC.referenceFieldNameLocaleMap[referenceFieldName])}): `;
      const value = R.slice(referencePrefix.length, Infinity, referenceName);

      return R.assoc(GC.FIELD_REFERENCE_NAME, value, item);
    }

    if (R.equals(dataType, 'selectMultiple')) return checkDataTypeInSearchCriteriaObject(item);

    const stringDataTypes = ['string:array', 'string:select'];

    if (R.includes(dataType, stringDataTypes)) return R.assoc('dataType', 'string', item);

    return item;
  }),
)(data);

export const transformPropDataFromSelectToString = (filterProps: Object) => filterProps.map(
  (item: Object) => {
    if (R.includes(item.type, ['selectMultiple', 'string:array'])) return R.assoc('type', 'string', item);

    return item;
  },
);

export const buildReport = (props: Object) => {
  const {
    prompt,
    isCreate,
    isUpdate,
    usedReport,
    formValues,
    showIssues,
    reportSortList,
    reportFieldList,
    showReportSummary,
    reportFilterGroups,
    disableSetReportFields,
    avoidRelatedValueDuplicates,
  } = props;

  let searchCriteria = R.compose(
    R.map((filter: Object) => {
      const { guid, name, groupId, origValue, withoutOrGroup } = filter;
      const groupIdToUse = G.ifElse(G.isTrue(withoutOrGroup), null, groupId);
      const guidToUse = G.ifElse(isCreate, null, guid);

      return {
        ...filter,
        guid: guidToUse,
        groupId: groupIdToUse,
        [GC.FIELD_NAME]: R.or(origValue, name),
      };
    }),
    R.reject((field: Object) => G.isNilOrEmpty(field.propertyName)),
    R.filter((field: Object) => G.notEquals(field.dataType, '')),
    R.reduce(R.concat, []),
    R.values,
  )(reportFilterGroups);

  if (R.or(isCreate, isUpdate)) {
    searchCriteria = transformSearchCriteriaBeforeReportPost(
      searchCriteria,
      G.getPropFromObject(GC.FIELD_TYPE, usedReport),
    );
  }

  const orderFields = R.compose(
    G.mapIndexed((filter: Object, i: number) => {
      const { guid, name, origValue } = filter;
      const guidToUse = G.ifElse(isCreate, null, guid);

      return R.mergeRight(filter, {
        sequence: R.inc(i),
        [GC.FIELD_GUID]: guidToUse,
        [GC.FIELD_NAME]: R.or(origValue, name),
      });
    }),
    R.filter(({ name }: Object) => G.isNotNilAndNotEmpty(name)),
  )(reportSortList);
  let fieldsToUse = [];
  let summaryParams = {};

  if (R.not(disableSetReportFields)) {
    fieldsToUse = R.compose(
      R.map((field: Object) => {
        const { guid, name, origValue } = field;

        return R.mergeRight(field, {
          [GC.FIELD_NAME]: R.or(origValue, name),
          [GC.FIELD_GUID]: G.ifElse(isCreate, null, guid),
        });
      }),
      R.filter((field: Object) => G.notEquals(field.name, '')),
    )(reportFieldList);
  }

  if (showReportSummary) {
    summaryParams = {
      [GC.FIELD_GROUP_BY]: R.pathOr(null, [GC.FIELD_GROUP_BY], formValues),
      [GC.FIELD_SUMMARY_GROUP]: R.pathOr(null, [GC.FIELD_SUMMARY_GROUP], formValues),
      [GC.FIELD_SHOW_LAST_DAYS]: R.pathOr(null, [GC.FIELD_SHOW_LAST_DAYS], formValues),
      [GC.FIELD_SUMMARY_SUBGROUP]: R.pathOr(null, [GC.FIELD_SUMMARY_SUBGROUP], formValues),
    };
  }

  fieldsToUse = R.compose(
    R.filter(({ name }: Object) => G.isNotNilAndNotEmpty(name)),
    mapFieldsWithSequenceFromIndex,
  )(fieldsToUse);

  return {
    ...usedReport,
    ...summaryParams,
    showIssues,
    orderFields,
    searchCriteria,
    fields: fieldsToUse,
    avoidRelatedValueDuplicates,
    prompt: G.ifElse(G.isNilOrEmpty(searchCriteria), false, prompt),
  };
};

export const checkReportFunction = (reports: Array) => {
  const reportType = R.path([0, GC.FIELD_TYPE], reports);

  const checkIsStringValueNotNil = (stringValue: string) => {
    if (G.isNotNil(stringValue)) return R.split(',', stringValue);

    return '';
  };

  const searchCriteriaObject = (data: Object) => {
    const filterValue = (option: Object) => R.includes(option.value, checkIsStringValueNotNil(data.stringValue));

    const searchCriteriaOptions = R.map(
      (item: Object) => item,
      R.filter(filterValue, fieldNameToTransform[data.propertyName](reportType)),
    );

    const newObject = R.compose(
      R.set(R.lensProp('dataType'), 'selectMultiple'),
      R.assoc('selectMultipleValue', searchCriteriaOptions),
      R.omit(['stringValue']),
    )(data);

    return newObject;
  };

  const newReportList = R.map(
    (report: Object) => {
      if (G.isNilOrEmpty(report.searchCriteria)) return report;

      const searchCriteria = R.map(
        (item: Object) => {
          if (R.includes(item.propertyName, R.keys(fieldNameToTransform))) return searchCriteriaObject(item);

          return item;
        },
        report.searchCriteria,
      );

      return R.set(R.lensProp('searchCriteria'), searchCriteria, report);
    },
    reports,
  );

  return newReportList;
};


const createdSearchCriteria = (data: object) => R.compose(
  R.set(R.lensProp('dataType'), 'string'),
  R.assoc('stringValue', R.join(',', R.map(R.prop('value'), R.path(['selectMultipleValue'], data)))),
  R.omit(['selectMultipleValue']),
)(data);

export const transformSearchCriteriaBeforeFilterPost = (data: Object, reportType: string) => {
  if (G.isNilOrEmpty(data)) return data;

  const { dataType, propertyName, referenceName, referenceFieldName } = data;

  if (R.and(R.equals(propertyName, GC.FIELD_REFERENCES), G.isNotNilAndNotEmpty(referenceFieldName))) {
    const referencePrefix = `Ref (${G.getWindowLocale(...GC.referenceFieldNameLocaleMap[referenceFieldName])}): `;
    const value = R.slice(referencePrefix.length, Infinity, referenceName);

    return R.assoc(GC.FIELD_REFERENCE_NAME, value, data);
  }

  if (R.equals(dataType, 'selectMultiple')) return createdSearchCriteria(data);

  return transformSearchItemByUomSystem(data, reportType);
};

export const transformFiltersValueForCharts = (filters: Array, options: Object) => {
  const checkIsStringValueNotNil = (stringValue: string) => {
    if (G.isNotNil(stringValue)) return R.split(',', stringValue);
    return '';
  };

  const searchCriteriaObject = (item: Object) => {
    const filterValue = (option: Object) => R.includes(option.value, checkIsStringValueNotNil(item.stringValue));
    const searchCriteriaOptions = R.map((item: Object) => item, R.filter(filterValue, options));
    const newObject = R.compose(
      R.set(R.lensProp('dataType'), 'selectMultiple'),
      R.assoc('selectMultipleValue', searchCriteriaOptions),
      R.omit(['stringValue']),
      )(item);
    return newObject;
  };

  if (G.isNilOrEmpty(filters)) return [];
  const newFilter = filters.map((item: Object) => {
    if (R.equals(item.propertyName, 'status')) return searchCriteriaObject(item);
    return item;
  });
  const newFilterObject = R.mergeRight(filters, newFilter);
  return newFilterObject;
};

export const renderReportBorderColor = (props: Object) => {
  if (R.and(props.error, props.touched)) {
    return G.getTheme('forms.inputs.borderColorErr');
  }
  return G.getTheme('colors.dark.grey');
};

const getIndexesFromDnDResult = (result: Object) => {
  const sourceIndex = R.path(['source', 'index'], result);
  const destinationIndex = R.path(['destination', 'index'], result);
  const destinationIndexChecked = G.ifElse(
    R.equals(destinationIndex, -1),
    0,
    destinationIndex,
  );
  return {
    sourceIndex,
    destinationIndexChecked,
  };
};

export const reorderFieldsOnDrugEnd = (sortedFields: Object, result: Object) => {
  const { sourceIndex, destinationIndexChecked } = getIndexesFromDnDResult(result);
  const fields = R.clone(sortedFields);
  const [removedEvent] = fields.splice(sourceIndex, 1);
  fields.splice(destinationIndexChecked, 0, removedEvent);
  return G.mapIndexed((stop: Object, index: number) => R.assoc('sequence', R.inc(index), stop), fields);
};

const validateOperation = (report: Object) => {
  let operationAbsent = false;
  report.searchCriteria.forEach((criteria: Object) => {
    const condition = G.ifElse(
      R.equals(criteria.dataType, 'date'),
      R.and(G.isNilOrEmpty(criteria.timeUnit), G.isNilOrEmpty(criteria.operation)),
      G.isNilOrEmpty(criteria.operation),
    );
    if (condition) operationAbsent = true;
  });
  return operationAbsent;
};

const validateRange = (report: Object) => {
  let rangeAbsent = false;
  report.searchCriteria.forEach((criteria: Object) => {
    const { operation, dataType } = criteria;
    let condition = false;
    if (R.equals(operation, 'range')) {
      const rangeValues = R.prop(`${dataType}Range`, criteria);
      condition = G.ifElse(
        R.all(G.isNilOrEmpty, R.values(rangeValues)),
        false,
        R.any(G.isNilOrEmpty, R.values(rangeValues)),
      );
    }
    if (condition) rangeAbsent = true;
  });
  return rangeAbsent;
};

// NOTE: check all report validate from report-format logic
export const isReportValid = (report: Object) => {
  const rangeAbsent = validateRange(report);
  const operationAbsent = validateOperation(report);
  if (rangeAbsent) {
    const message = G.getWindowLocale(
      'message:range-fields-empty-or-filled-in',
      'Range fields should both be empty or both filled in',
    );
    G.showToastrMessageSimple('info', message);
    return false;
  }
  if (operationAbsent) {
    G.showToastrMessageSimple(
      'info',
      G.getWindowLocale(
        'messages:select-operation-for-report-filters',
        'Please, select operations for all selected filter fields',
      ),
    );
    return false;
  }
  return true;
};

export const isNotReportValid = (report: Object) => R.not(isReportValid(report));

export const getAssessorials = ({ usedReport, assessorials }: Object) => {
  const allowedTypes = [
    GC.TEL_REPORT,
    GC.CLO_REPORT,
    GC.PAYROLL_REPORT,
    GC.PIVOT_TEL_REPORT,
    GC.PIVOT_CLO_REPORT,
    GC.VENDOR_PAYROLL_REPORT,
    GC.CUSTOMER_INVOICE_REPORT,
    GC.PIVOT_DRIVER_PAYROLL_REPORT,
    GC.PIVOT_VENDOR_PAYROLL_REPORT,
    GC.PIVOT_CUSTOMER_INVOICE_REPORT,
  ];

  const type = R.path([GC.FIELD_TYPE], usedReport);

  if (R.includes(type, allowedTypes)) return assessorials;

  return [];
};

export const getAvailableForReports = (props: Object, reportFieldList: Array) => {
  const availableForReport = R.compose(
    R.reject(({ disableField }: Object) => {
      if (G.isBoolean(disableField)) return disableField;
    }),
    R.pathOr([], ['availableFields']),
  )(props);

  return availableForReport.filter((af: Object) => (
    R.not(reportFieldList.find((f: Object) => R.equals(f.name, af.value)))
  ));
};

export const getAvailableForReportSort = (props: Object, reportFieldList: Array) => {
  const availableForSort = R.compose(
    R.reject(({ disableSort, disableFilter }: Object) => {
      if (G.isBoolean(disableSort)) return disableSort;

      return disableFilter;
    }),
    R.pathOr([], ['availableFields']),
  )(props);

  return availableForSort.filter((af: Object) => (
    R.not(reportFieldList.find((f: Object) => R.equals(f.name, af.value)))
  ));
};

export const getAvailableFilterFields = (props: Object) => {
  const { usedReport, geoFencingZones, availableFields, additionalFilters, useAdditionalFilters } = props;

  if (G.isTrue(useAdditionalFilters)) return additionalFilters;

  const reportType = G.getPropFromObject(GC.FIELD_TYPE, usedReport);

  const filteredList = R.filter(
    ({ disableFilter }: Object) => R.not(disableFilter),
    R.or(availableFields, []),
  );

  const typesWithGeoFencingZones = [
    GC.TEL_REPORT,
    GC.CLO_REPORT,
    GC.PIVOT_CLO_REPORT,
    GC.PIVOT_TEL_REPORT,
    GC.CLO_EVENT_REPORT,
    GC.ROUTE_TEL_REPORT,
    GC.ROUTE_CLO_REPORT,
  ];

  if (G.notContain(reportType, typesWithGeoFencingZones)) {
    return filteredList;
  }

  const options = G.mapNameGuidToLabelValue(geoFencingZones);

  const dispatchReportGeoFencingZoneFields = [
    {
      options,
      type: 'string:select',
      value: GC.FIELD_ORIGIN_ZONE,
      name: G.getWindowLocale('titles:origin-zone', 'Origin Zone'),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
    {
      options,
      type: 'string:select',
      value: GC.FIELD_DESTINATION_ZONE,
      name: G.getWindowLocale('titles:destination-zone', 'Destination Zone'),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
  ];

  const geoFencingFieldNamesMap = {
    [GC.TEL_REPORT]: dispatchReportGeoFencingZoneFields,
    [GC.CLO_REPORT]: dispatchReportGeoFencingZoneFields,
    [GC.PIVOT_CLO_REPORT]: dispatchReportGeoFencingZoneFields,
    [GC.PIVOT_TEL_REPORT]: dispatchReportGeoFencingZoneFields,
    [GC.ROUTE_TEL_REPORT]: dispatchReportGeoFencingZoneFields,
    [GC.ROUTE_CLO_REPORT]: dispatchReportGeoFencingZoneFields,
    [GC.CLO_EVENT_REPORT]: [{
      options,
      type: 'string:select',
      value: GC.FIELD_LOCATION_ZONE,
      name: G.getWindowLocale('titles:location-zone', 'Location Zone'),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    }],
  };

  return R.concat(filteredList, G.getPropFromObject(reportType, geoFencingFieldNamesMap));
};

export const setOptions = (options: Array) => {
  if (G.isNotNilAndNotEmpty(options)) {
    return R.map((option: Object) => ({
      [GC.FIELD_LABEL]: option.name,
      ...R.dissoc('options', option),
    }), options);
  }
};
