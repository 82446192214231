import * as R from 'ramda';
import React from 'react';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { Filter } from '../filter';
import { Switcher } from '../switcher';
import { PageTitle } from '../page-title';
import AdditionalFilters from '../additional-filters';
import { PageHeaderActions } from '../page-header-actions';
import { QuickFilterOutsideControl } from '../quick-filter';
import ReportFormatPanel from '../edit-report/components/report-format-panel';
import { defaultFilterFields } from '../edit-report/components/filter-list/settings';
import { FilterPopup, PageReportFilters, AddReportFilterOutside } from '../filter/report-and-quick-filters';
// features
import { makeSelectCurrentUserName } from '../../features/auth/selectors';
import { makeSelectAccessorialsList } from '../../features/configurations/selectors';
import { makeSelectAllAvailableReferenceTypesByScopeName } from '../../features/reference/selectors';
import { makeSelectAvailableReportGeoFencingZoneList } from '../../features/geo-fencing-zone/report/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withAsyncPinReport } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, SimpleWrapper, DisableEventsBox, PageHeaderWrapper } from '../../ui';
// component title-panel
import { RightTitlePanel } from './ui';
import { PinnedReports } from './components/pinned-reports';
//////////////////////////////////////////////////

export const ReportTemplates = (props: Object) => {
  const {
    pt,
    px,
    width,
    reportList,
    selectedReport,
    handleSelectReport,
    handlePinOrUnpinReport,
  } = props;

  const blueColor = G.getTheme('colors.dark.blue');
  const filtered = R.filter(({ pinnedReport }: string) => pinnedReport, R.or(reportList, []));

  if (G.isNilOrEmpty(filtered)) return null;

  return (
    <Flex pt={pt} px={R.or(px, 20)} flexWrap='wrap' width={R.or(width, '100%')}>
      {
        filtered.map(({ guid, name, pinnedReport }: Object, index: number) => (
          <Flex
            p='5px'
            mr={10}
            mb='6px'
            key={index}
            border='1px solid'
            borderRadius='4px'
            borderColor={blueColor}
            background={G.getThemeByCond(
              R.pathEq(guid, [GC.FIELD_GUID], selectedReport),
              'colors.lightGreenHalfOpacity',
              'colors.white',
            )}
          >
            <Box cursor='pointer' onClick={() => handleSelectReport(guid)}>
              {name}
            </Box>
            <Box
              ml={10}
              height={15}
              cursor='pointer'
              onClick={() => handlePinOrUnpinReport({ pinnedReport, reportGuid: guid })}
            >
              {I.trash(blueColor)}
            </Box>
          </Flex>
        ))
      }
    </Flex>
  );
};

export const renderTitle = (props: Object) => {
  const {
    mr,
    title,
    display,
    options,
    onSwitch,
    withCount,
    totalCount,
    withReload,
    reloadAction,
    selectedOptionIndex,
    customTitleComponent,
    useMultiswitchComponent,
  } = props;

  if (G.isNotNilAndNotEmpty(customTitleComponent)) {
    return customTitleComponent;
  }

  if (useMultiswitchComponent) {
    return (
      <SimpleWrapper>
        <PageTitle
          mr='32px'
          title={title}
          count={totalCount}
          withCount={withCount}
        />
        <Switcher
          version={2}
          options={options}
          onSwitch={onSwitch}
          selectedOptionIndex={selectedOptionIndex}
        />
      </SimpleWrapper>
    );
  }

  let label = null;

  if (G.isTrue(withReload)) {
    label = (
      <Box ml='6px' cursor='pointer' onClick={reloadAction}>{I.reloadCircle()}</Box>
    );
  }

  return (
    <PageTitle
      mr={mr}
      label={label}
      title={title}
      display={display}
      count={totalCount}
      withCount={withCount}
    />
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  userLoginId: makeSelectCurrentUserName(state),
  assessorials: makeSelectAccessorialsList(state),
  geoFencingZones: makeSelectAvailableReportGeoFencingZoneList(state),
  allAvailableRefTypesByScopeName: makeSelectAllAvailableReferenceTypesByScopeName(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withAsyncPinReport,
);

export const TitlePanel = enhance((props: Object) => {
  const {
    pt,
    pr,
    pb,
    type,
    name,
    zIndex,
    loading,
    reportList,
    alignItems,
    filterProps,
    noEditReport,
    noExportable,
    filterParams,
    reportOptions,
    withoutReport,
    selectedReport,
    editReportText,
    useExactFilters,
    titlePanelStyles,
    disableRefFilter,
    handleEditReport,
    noSelectDropdown,
    selectReportText,
    globalFilterValue,
    pageHeaderActions,
    exportPermissions,
    hideReportFilters,
    hidePinnedReports,
    withoutQuickFilter,
    handleSelectReport,
    selectedListLength,
    additionalComponent,
    withoutPinnedReports,
    setQuickFilterParams,
    selectReportFormName,
    withAdditionalFilters,
    handleSetGlobalFilter,
    hiddenRightFilterInfo,
    rightTitlePanelZIndex,
    hiddenAddReportFilter,
    shouldSubmitFilterForm,
    handlePinOrUnpinReport,
    withGlobalSearchFilter,
    additionalFilterOptions,
    exportReportDataRequest,
    pinnedReportsPanelAction,
    changeDefaultReportRequest,
    getQuickFilteredListRequest,
    additionalFiltersApplyHandler,
    pinnedReportsPanelActionTitle,
    allAvailableRefTypesByScopeName,
    allowSetAdditionalFiltersImmediately,
  } = props;

  const version = 2;
  const searchInputColor = G.getTheme('colors.dark.grey');
  const references = G.getRefListByReportType(allAvailableRefTypesByScopeName, R.path([GC.FIELD_TYPE], selectedReport));

  const pinnedReports = R.compose(
    R.sortBy(R.prop(GC.FIELD_PINNED_REPORT_INDEX)),
    R.filter(({ pinnedReport }: Object) => pinnedReport),
  )(R.or(reportList, []));

  return (
    <DisableEventsBox {...titlePanelStyles} disableEvents={loading}>
      <PageHeaderWrapper
        pt={pt}
        pr={pr}
        pb={pb}
        height='auto'
        position='relative'
        justify='space-between'
        zI={G.ifElse(R.isNotNil(zIndex), R.inc(zIndex), 1)}
      >
        <Flex zIndex={10} alignItems={alignItems}>
          {renderTitle(props)}
          {G.isNotNilAndNotEmpty(additionalComponent) && additionalComponent}
          {
            G.isTrue(withAdditionalFilters) &&
            <AdditionalFilters
              additionalFilterOptions={additionalFilterOptions}
              additionalFiltersApplyHandler={additionalFiltersApplyHandler}
              allowSetAdditionalFiltersImmediately={allowSetAdditionalFiltersImmediately}
            />
          }
          {
            G.isTrue(withGlobalSearchFilter) &&
            <QuickFilterOutsideControl
              withoutLabel={true}
              outsideValue={globalFilterValue}
              shouldClearTimeoutOnKeyPress={true}
              handleSetFilter={handleSetGlobalFilter}
              onKeyPressHandler={handleSetGlobalFilter}
              endLabel={<Box mr='8px'>{I.search(searchInputColor)}</Box>}
              placeholder={G.getWindowLocale('titles:search', 'Search')}
              inputProps={{ p: '0 0 0 8px', height: '28px', withoutBorder: true }}
              boxStyles={{
                ml: 20,
                border: '1px solid',
                borderRadius: '2px',
                borderColor: searchInputColor,
                bg: G.getTheme('colors.light.mainLight'),
              }}
            />
          }
          {
            useExactFilters &&
            <FilterPopup
              {...props}
              version={version}
              additionalStyles={{ popperComponentWrapperMargin: '0 0 0 15px' }}
            />
          }
          {
            G.isNotNilAndNotEmpty(pageHeaderActions) &&
            <PageHeaderActions count={selectedListLength} pageHeaderActions={pageHeaderActions} />
          }
        </Flex>
        {
          G.isFalse(hiddenRightFilterInfo) &&
          <RightTitlePanel zIndex={rightTitlePanelZIndex}>
            {
              G.isAllTrue(
                R.not(hiddenAddReportFilter),
                G.isNilOrEmpty(filterParams),
                G.isNilOrEmpty(R.path(['searchCriteria'], selectedReport)),
              ) &&
              <AddReportFilterOutside
                {...props}
                isNew={true}
                refList={references}
                filterData={defaultFilterFields}
              />
            }
            {
              R.not(withoutReport) &&
              <ReportFormatPanel
                version={version}
                reportType={type}
                noEditReport={noEditReport}
                noExportable={noExportable}
                editReportText={editReportText}
                selectedReport={selectedReport}
                selectAction={handleSelectReport}
                selectReportText={selectReportText}
                noSelectDropdown={noSelectDropdown}
                exportPermissions={exportPermissions}
                options={R.or(reportOptions, reportList)}
                selectReportFormName={selectReportFormName}
                exportReportDataRequest={exportReportDataRequest}
                editReportAction={() => handleEditReport(filterProps)}
                changeDefaultReportRequest={changeDefaultReportRequest}
                filterBy={G.setFilterByParams(references, filterProps)}
                initialValues={{'report-panel': R.pathOr('', [GC.FIELD_NAME], selectedReport)}}
                handlePinOrUnpinReport={G.ifElse(R.not(withoutPinnedReports), handlePinOrUnpinReport)}
              />
            }
            {
              R.not(withoutQuickFilter) &&
              <Filter
                type={type}
                name={name}
                version={version}
                usedReport={selectedReport}
                setFilterParam={setQuickFilterParams}
                filterAction={getQuickFilteredListRequest}
                shouldSubmitFilterForm={shouldSubmitFilterForm}
                filterBy={G.setFilterByParams(references, filterProps, { disableRefFilter })}
              />
            }
          </RightTitlePanel>
        }
      </PageHeaderWrapper>
      {
        R.or(
          G.isFunction(pinnedReportsPanelAction),
          R.and(G.isNotEmpty(pinnedReports), R.not(hidePinnedReports)),
        ) &&
        <PinnedReports
          zIndex={zIndex}
          list={pinnedReports}
          selectedReport={selectedReport}
          handleSelectReport={handleSelectReport}
          handlePinOrUnpinReport={handlePinOrUnpinReport}
          pinnedReportsPanelAction={pinnedReportsPanelAction}
          changeDefaultReportRequest={changeDefaultReportRequest}
          pinnedReportsPanelActionTitle={pinnedReportsPanelActionTitle}
        />
      }
      {
        R.and(
          R.or(G.isNotNilAndNotEmpty(selectedReport), G.isNotNilAndNotEmpty(filterParams)),
          R.not(hideReportFilters),
        ) &&
        <PageReportFilters {...props} />
      }
    </DisableEventsBox>
  );
});

export default TitlePanel;
