import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import {
  pure,
  compose,
  withProps,
  withState,
  shouldUpdate,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
import { FixedSizeList as List } from 'react-window';
// common
import {
  getReportTableResize,
  deleteTableResizeByGuid,
  deleteTableResizeByType,
  updateTableResizeByGuid,
} from '../../common/idb/resize/actions';
import {
  makeSelectIDB,
  makeSelectIDBProps,
  makeSelectResizeTable,
} from '../../common/idb/resize/selectors';
// components
import { LocalLoader } from '../local-loader';
import { openModal, closeModal } from '../modal/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// component virtualized-table
import { Row } from './components/table-row';
import HeaderRow from './components/table-header';
import { TableWrapper, LoaderWrapper } from './ui';
import AddColumn from './components/add-column-popup';
import ResizeActions from './components/resize-actions';
import VirtualizedListContext from './components/context';
import { getOptionsFromAdditionalSettings } from './components/helpers'
//////////////////////////////////////////////////

const ItemWrapper = ({ data, index, style } : Object) => {
  const { count, handleLazyLoading, ItemRenderer } = data;

  if (G.isZero(index)) {
    return null;
  }

  if (R.equals(index, R.dec(count))) {
    handleLazyLoading();
  }

  return <ItemRenderer index={index} style={style} />;
};

const innerElementType = forwardRef(({ children, style, ...rest } : Object, ref : Object) => (
  <VirtualizedListContext.Consumer>
    {({
      columns,
      fontSize,
      stickyLeft,
      filterProps,
      onOptionClick,
      columnSettings,
      resizeObserver,
      titleRowHeight,
      titleSortValues,
      withRightActions,
      titleFilterValues,
      handleClickResetIcon,
      handleTableTitleFilter,
    } : Object) => (
      <div
        {...rest}
        ref={ref}
        style={{ ...style, position: 'relative', minWidth: '100%', width: 'fit-content' }}
      >
        <HeaderRow
          columns={columns}
          fontSize={fontSize}
          stickyLeft={stickyLeft}
          filterProps={filterProps}
          onOptionClick={onOptionClick}
          columnSettings={columnSettings}
          resizeObserver={resizeObserver}
          titleSortValues={titleSortValues}
          withRightActions={withRightActions}
          titleFilterValues={titleFilterValues}
          handleClickResetIcon={handleClickResetIcon}
          handleTableTitleFilter={handleTableTitleFilter}
          style={{ top: 0, left: 0, width: '100%', height: titleRowHeight }}
        />
        {children}
      </div>
    )}
  </VirtualizedListContext.Consumer>
));

const StickyList = ({
  count,
  columns,
  children,
  listData,
  fontSize,
  isLoading,
  stickyLeft,
  filterProps,
  rowTopMargin,
  actionButtons,
  onOptionClick,
  columnSettings,
  resizeObserver,
  setEditedValue,
  tableRowHeight,
  titleRowHeight,
  titleSortValues,
  withRightActions,
  handleLazyLoading,
  titleFilterValues,
  handleClickResetIcon,
  handleTableTitleFilter,
  ...rest
}: Object) => (
  <VirtualizedListContext.Provider
    value={useMemo(() => ({
      columns,
      fontSize,
      listData,
      isLoading,
      stickyLeft,
      filterProps,
      rowTopMargin,
      actionButtons,
      onOptionClick,
      columnSettings,
      resizeObserver,
      setEditedValue,
      tableRowHeight,
      titleRowHeight,
      titleSortValues,
      withRightActions,
      titleFilterValues,
      handleClickResetIcon,
      handleTableTitleFilter,
      ItemRenderer: children,
    }), [
      columns,
      children,
      fontSize,
      listData,
      isLoading,
      stickyLeft,
      filterProps,
      rowTopMargin,
      actionButtons,
      onOptionClick,
      columnSettings,
      resizeObserver,
      setEditedValue,
      tableRowHeight,
      titleRowHeight,
      titleSortValues,
      withRightActions,
      titleFilterValues,
      handleClickResetIcon,
      handleTableTitleFilter,
    ])}
  >
    <List itemData={{ ItemRenderer: children, count, handleLazyLoading }} {...rest}>
      {ItemWrapper}
    </List>
  </VirtualizedListContext.Provider>
);

const getColumns = ({
  report,
  stickyList,
  resizeByGuid,
  actionButtons,
  columnSettings,
  withRightActions,
  additionalColumns,
  withCheckbox = false,
  withResizableColumns,
  withLeftActions = false,
}) => {
  const actionButtonsWidth = R.add(R.multiply(30, R.length(actionButtons)), 10);

  let fieldsForColumns = [];

  const reportFields = R.prop('fields', report);

  if (G.isNotNilAndNotEmpty(reportFields)) {
    fieldsForColumns = R.pluck('name', reportFields);

    const freezed = R.pluck('name', R.filter(R.propEq(true, 'freezed'), reportFields));

    stickyList.push(...freezed);
  } else {
    fieldsForColumns = R.filter(key => {
      const setting = columnSettings[key];

      return !setting.additional || additionalColumns.indexOf(key) > -1
    }, Object.keys(columnSettings));
  }

  const stickyListLength = R.length(stickyList);
  const bySticky = (l: string, r: string) => G.ifElse(R.includes(r, stickyList), -1, 0);

  if (R.gt(stickyListLength, 0)) {
    const sorted = R.sort(bySticky, fieldsForColumns);
    const sticked = R.reverse(sorted.splice(-stickyListLength));

    sorted.unshift(...sticked);

    fieldsForColumns = sorted;
  }

  const columns = fieldsForColumns.map((key: string) => {
    const setting = columnSettings[key];

    const { width, notResizable } = setting;

    const currentWidth = resizeByGuid[key] || width;

    return {
      ...setting,
      fieldName: key,
      width: currentWidth,
      defaultWidth: width,
      resizable: R.and(R.not(notResizable), withResizableColumns),
    };
  });

  if (withLeftActions) {
    columns.unshift({
      sticky: true,
      resizable: false,
      name: 'left-actions',
      fieldName: 'leftActions',
      width: actionButtonsWidth,
      defaultWidth: actionButtonsWidth,
    });
  }

  if (withCheckbox) {
    columns.unshift({
      width: 40,
      sticky: true,
      defaultWidth: 40,
      resizable: false,
      name: 'checkboxes',
      fieldName: 'checkboxes',
    });
  }

  if (withRightActions) {
    columns.push({
      width: 50,
      defaultWidth: 50,
      resizable: false,
      name: 'right-actions',
      fieldName: 'rightActions',
    });
  }

  const stickyLength = R.add(R.reduce(R.add, 0)([
    withCheckbox,
    withLeftActions,
  ]), stickyListLength);

  return {
    columns,
    stickyLength,
  };
}

const VirtualizedTable = memo(({
  report,
  fontSize,
  listData,
  stickyList = [],
  resizeTable,
  tableHeight,
  filterProps,
  withCheckbox,
  actionButtons = [],
  onOptionClick,
  columnSettings,
  tableRowHeight,
  titleRowHeight,
  withLeftActions,
  withLazyLoading,
  withRightActions,
  additionalColumns,
  getReportTableResize,
  handleClickResetIcon,
  titleSortValues = {},
  tableTopIndent = 140,
  withResizableColumns,
  handleTableTitleFilter,
  titleFilterValues = {},
  updateTableResizeByGuid,
  setTableCellNewValue = () => {},
}: Object) => {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const totalCount = R.length(listData);
  const pageLength = G.ifElse(withLazyLoading, 20, totalCount); //TODO: figure out proper solution
  const limit = G.ifElse(R.gte(totalCount, pageLength), pageLength, totalCount);
  const count = R.add(offset, limit);

  let limitHeight = R.multiply(R.inc(limit), R.add(tableRowHeight, 8));

  if (R.lt(limitHeight, 50)) limitHeight = 50;

  const [windowHeight] = windowSize;

  const maxHeight = G.ifElse(
    R.gt(tableHeight, limitHeight),
    limitHeight,
    R.or(tableHeight , windowHeight - tableTopIndent)
  );

  const reportType = R.prop(GC.FIELD_TYPE, report);
  const guid = R.prop(GC.FIELD_GUID, report);
  const resizeByGuid = useMemo(() => R.pathOr({}, [guid], resizeTable), [guid, resizeTable]);

  const setEditedValue = useCallback((marker, value) => {
    // here we set value via call external method, test it
    setTableCellNewValue(marker, value);
  }, []);

  const { columns, stickyLength } = useMemo(() => getColumns({
    report,
    stickyList,
    resizeByGuid,
    withCheckbox,
    actionButtons,
    columnSettings,
    withLeftActions,
    withRightActions,
    additionalColumns,
    withResizableColumns,
  }), [
    report,
    stickyList,
    resizeByGuid,
    withCheckbox,
    actionButtons,
    columnSettings,
    withLeftActions,
    withRightActions,
    additionalColumns,
    withResizableColumns,
  ]);

  //TODO: use real API calls outside of table
  const rowTopMargin = useMemo(() => R.subtract(titleRowHeight, tableRowHeight), [titleRowHeight, tableRowHeight]);

  const handleLazyLoading = useCallback(() => {
    if (R.gte(count, totalCount)) return;

    setIsLoading(true);

    setTimeout(() => {
      setOffset(R.add(offset, limit));
      setIsLoading(false);
    }, 500);
  }, [offset, count, totalCount]);

  const handleUpdateTableResize = useCallback((resizedTableField: Object) => {
    updateTableResizeByGuid({
      reportType,
      reportGuid: guid,
      resizedTableField,
    });
  }, [reportType, guid]);

  const [resizeObserver, setResizeObserver] = useState();

  useEffect(() => {
    if (withResizableColumns) {
      resizeObserver?.disconnect();
      // eslint-disable-next-line no-undef
      const resizeObserverInstance = new ResizeObserver(
        G.setDebounce((entries: Object) => {
          if (R.gt(R.prop('length', entries), 1)) return;

          if (R.pathOr(false, [0, 'borderBoxSize'], entries)) {
            const target = entries[0].target;
            const newWidth = R.pathOr(null, [0, 'borderBoxSize', 0, 'inlineSize'], entries);
            const fieldName = G.getPropFromObject(GC.FIELD_NAME, target);
            const storedWidth = R.prop(fieldName, resizeByGuid);
            const width = R.path([fieldName, 'width'], columnSettings);

            if (R.or(
              R.or(
                R.equals(newWidth, 0),
                R.equals(storedWidth, newWidth),
              ),
              R.and(
                R.isNil(storedWidth),
                R.equals(width, newWidth),
              ),
            )) return;

            handleUpdateTableResize({fieldName, width: newWidth});
          }
        }, 200),
      );

      setResizeObserver(resizeObserverInstance);
    }
  }, [
    report,
    resizeByGuid,
    columnSettings,
    additionalColumns,
    withResizableColumns,
  ]);

  useEffect(() => {
    if (R.not(guid)) return;

    if (withResizableColumns) {
      getReportTableResize(guid);
    }

    return () => {
      if (withResizableColumns) {
        resizeObserver?.disconnect();
        setResizeObserver(null);
      }
    }
  }, [guid]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <TableWrapper>
      <StickyList
        width='100%'
        count={count}
        columns={columns}
        height={maxHeight}
        fontSize={fontSize}
        listData={listData}
        isLoading={isLoading}
        itemCount={R.inc(count)}
        filterProps={filterProps}
        stickyLeft={stickyLength}
        itemSize={tableRowHeight}
        rowTopMargin={rowTopMargin}
        actionButtons={actionButtons}
        onOptionClick={onOptionClick}
        columnSettings={columnSettings}
        resizeObserver={resizeObserver}
        tableRowHeight={tableRowHeight}
        titleRowHeight={titleRowHeight}
        setEditedValue={setEditedValue}
        titleSortValues={titleSortValues}
        innerElementType={innerElementType}
        withRightActions={withRightActions}
        titleFilterValues={titleFilterValues}
        handleClickResetIcon={handleClickResetIcon}
        handleTableTitleFilter={handleTableTitleFilter}
        handleLazyLoading={G.setDebounce(handleLazyLoading, 250)}
      >
        {Row}
      </StickyList>
      {
        isLoading &&
        <LoaderWrapper>
          <LocalLoader minWidth={340} localLoaderOpen={true} />
        </LoaderWrapper>
      }
    </TableWrapper>
  );
});

const enhance = compose(
  withProps(() => ({
    popoverOverflow: 'auto',
  })),
  withFixedPopover,
  withState('additionalColumns', 'setAdditionalColumns', []),
  withPropsOnChange(['additionalColumns'], (props: Object) => ({
    newColumnOptions: getOptionsFromAdditionalSettings(props),
  })),
  withHandlers({
    handleSubmitAddNewColumn: ({
      closeModal,
      additionalColumns,
      setAdditionalColumns,
    }: Object) => (column) => {
      closeModal();

      const columns = R.clone(additionalColumns);

      columns.push(column);

      setAdditionalColumns(columns);
    },
  }),
  withHandlers({
    handleClickResetByGuid: ({
      report,
      deleteTableResizeByGuid,
    }: Object) => () => {
      const guid = R.prop(GC.FIELD_GUID, report);

      if (R.isNil(guid)) return;

      deleteTableResizeByGuid(guid);
    },
    handleClickResetByType: ({
      report,
      deleteTableResizeByType,
    }: Object) => () => {
      const reportGuid = R.prop(GC.FIELD_GUID, report);
      const reportType = R.prop(GC.FIELD_TYPE, report);

      if (R.isNil(reportType)) return;

      deleteTableResizeByType({ reportType, reportGuid });
    },
    handleAddNewColumn: ({
      openModal,
      closeFixedPopup,
      newColumnOptions,
      handleSubmitAddNewColumn,
    }: Object) => () => {
      closeFixedPopup();

      const component = (
        <AddColumn
          newColumnOptions={newColumnOptions}
          submitAction={handleSubmitAddNewColumn}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('actions:add-column', 'Add column'),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleClickResetIcon: ({
      report,
      openFixedPopup,
      closeFixedPopup,
      newColumnOptions,
      handleAddNewColumn,
      handleClickResetByGuid,
      handleClickResetByType,
    }: Object) => (e: Object) => {
      const reportType = R.prop(GC.FIELD_TYPE, report);

      return openFixedPopup({
        zIndex: 2,
        position: 'right',
        el: e.currentTarget,
        content: (
          <ResizeActions
            reportType={reportType}
            closeFixedPopup={closeFixedPopup}
            newColumnOptions={newColumnOptions}
            handleAddNewColumn={handleAddNewColumn}
            handleClickResetByGuid={handleClickResetByGuid}
            handleClickResetByType={handleClickResetByType}
          />
        ),
      });
    },
  }),
  pure,
  shouldUpdate((props: Object, nextProps: Object) => R.not(R.equals(props, nextProps))),
);

const mapStateToProps = (state: Object) => (createStructuredSelector({
  idb: makeSelectIDB(state),
  idbProps: makeSelectIDBProps(state),
  resizeTable: makeSelectResizeTable(state),
}));

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  getReportTableResize,
  updateTableResizeByGuid,
  deleteTableResizeByGuid,
  deleteTableResizeByType,
})(enhance(VirtualizedTable));

