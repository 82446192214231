import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import Select from 'react-select';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// components
import { Switcher } from '../../switcher';
import { MuiTextInput } from '../../mui-text-input';
import { DatePickerMui } from '../../datepicker-mui';
import { QuickFilterOutsideControl, QuickFilterOutsideControl3 } from '../../quick-filter';
// forms
import { DatePicker, CalendarFormGroupWithoutZI } from '../../../forms';
//////////////////////////////////////////////////

const SelectFilter = (props: Object) => {
  const {
    title,
    width,
    options,
    fieldProps,
    titleFilterValue,
    handleTableTitleFilter,
  } = props;

  let optionsToUse = options;

  if (G.isFunction(options)) optionsToUse = options();

  if (G.isNilOrEmpty(R.path([0, GC.FIELD_VALUE], optionsToUse))) {
    optionsToUse = R.tail(optionsToUse);
  }

  const styles = {
    container: (baseStyles: Object) => ({
      ...baseStyles,
      width,
    }),
    valueContainer: (baseStyles: Object) => ({
      ...baseStyles,
      paddingRight: 0,
    }),
    placeholder: (baseStyles: Object) => ({
      ...baseStyles,
      fontSize: 12,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: G.getTheme('colors.darkGrey'),
    }),
    singleValue: (baseStyles: Object) => ({
      ...baseStyles,
      margin: 0,
      fontSize: 12,
      color: G.getTheme('colors.textDark'),
    }),
    clearIndicator: (baseStyles: Object) => ({
      ...baseStyles,
      padding: 0,
    }),
    dropdownIndicator: (baseStyles: Object) => ({
      ...baseStyles,
      padding: 0,
    }),
    menuPortal: (baseStyles: Object) => ({
      ...baseStyles,
      zIndex: 1300,
    }),
    control: (baseStyles: Object) => ({
      ...baseStyles,
      minHeight: 32,
      borderRadius: 4,
      borderColor: G.getTheme('colors.borderGray'),
    }),
  };

  return (
    <Select
      styles={styles}
      isClearable={true}
      placeholder={title}
      maxMenuHeight={190}
      menuPlacement='auto'
      options={optionsToUse}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      value={R.find(R.propEq(R.pathOr('', [GC.FIELD_STRING_VALUE], titleFilterValue), GC.FIELD_VALUE), optionsToUse)}
      components={{
        IndicatorSeparator: () => null,
      }}
      onChange={(data: any) => {
        let value = '';

        if (G.isNotNil(data)) value = data.value;

        G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps });
      }}
    />
  );
};

const TitleFilter = (props: Object) => {
  const {
    title,
    width,
    maxWidth,
    fontSize,
    fieldProps,
    titleFilterValue,
    handleTableTitleFilter,
    useNewTitleFilterInputs,
  } = props;

  const { type, value, options } = fieldProps;

  if (R.or(G.isArray(options), G.isFunction(options))) {
    return (
      <SelectFilter
        title={title}
        width={width}
        options={options}
        fieldProps={fieldProps}
        titleFilterValue={titleFilterValue}
        handleTableTitleFilter={handleTableTitleFilter}
      />
    );
  }

  if (R.and(G.isTrue(useNewTitleFilterInputs), R.equals(type, 'string'))) {
    return (
      <QuickFilterOutsideControl3
        label={title}
        inputStyles={{ width, maxWidth }}
        shouldClearTimeoutOnKeyPress={true}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
        outsideValue={R.pathOr('', [GC.FIELD_STRING_VALUE], titleFilterValue)}
        handleSetFilter={(value: string) => G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps })}
        onKeyPressHandler={(value: string) => G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps })}
      />
    );
  }

  if (R.and(G.isTrue(useNewTitleFilterInputs), R.equals(type, 'number'))) {
    return (
      <QuickFilterOutsideControl3
        type={type}
        label={title}
        allowSingleCharacter={true}
        inputStyles={{ width, maxWidth }}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
        outsideValue={R.pathOr('', [GC.FIELD_NUMBER_VALUE], titleFilterValue)}
        handleSetFilter={(value: string) => G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps })}
      />
    );
  }

  if (R.and(G.isTrue(useNewTitleFilterInputs), R.equals(type, 'reference'))) {
    return (
      <QuickFilterOutsideControl3
        type={type}
        label={title}
        allowSingleCharacter={true}
        inputStyles={{ width, maxWidth }}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
        outsideValue={R.pathOr('', [GC.FIELD_REFERENCE_VALUE], titleFilterValue)}
        handleSetFilter={(value: string) => G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps })}
      />
    );
  }

  if (R.equals(type, 'date')) {
    const inputValue = R.compose(
      R.head,
      R.split(' '),
      R.pathOr('', [GC.FIELD_FROM]),
    )(titleFilterValue);

    const selected = G.ifElse(
      G.isNotNilAndNotEmpty(inputValue),
      moment(inputValue),
      null,
    );

    const placeholder = G.getWindowLocale('titles:select-date', 'Select Date');

    const onChange = (date: Object) => {
      let value = date;

      if (G.isNotNil(date)) value = date.format(GC.DEFAULT_DATE_FORMAT);

      handleTableTitleFilter({ value, fieldProps });
    };

    const configUseMuiCalendar = G.getAmousConfigByNameFromWindow(GC.UI_USE_MUI_CALENDAR);

    if (G.isFalse(configUseMuiCalendar)) {
      return (
        <CalendarFormGroupWithoutZI direction='row'>
          <DatePicker
            mb='4px'
            id={value}
            width={120}
            height='24px'
            boxShadow='unset'
            autoComplete='off'
            value={inputValue}
            isClearable={true}
            fontSize={fontSize}
            selected={selected}
            onChange={onChange}
            showTimeSelect={false}
            showYearDropdown={true}
            showMonthDropdown={true}
            placeholderText={placeholder}
            customInput={
              G.isTrue(useNewTitleFilterInputs) &&
              <MuiTextInput label={title} placeholder={placeholder} />
            }
          />
        </CalendarFormGroupWithoutZI>
      );
    }

    return (
      <DatePickerMui
        label={title}
        value={inputValue}
        isClearable={true}
        onAccept={onChange}
        inputReadOnly={true}
        withCalendarIcon={false}
        useNewMuiInputField={useNewTitleFilterInputs}
        width={G.ifElse(G.isTrue(useNewTitleFilterInputs), 180, 120)}
        placeholder={G.getWindowLocale('titles:select-date', 'Select Date')}
        inputSettings={{
          fontSize,
          id: value,
          mb: '4px',
          height: 24,
          p: '0 0 0 8px',
          boxShadow: 'unset',
        }}
      />
    );
  }

  if (R.equals(type, 'string')) {
    return (
      <QuickFilterOutsideControl
        withoutLabel={true}
        boxStyles={{ mb: '4px' }}
        shouldClearTimeoutOnKeyPress={true}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
        outsideValue={R.pathOr('', [GC.FIELD_STRING_VALUE], titleFilterValue)}
        handleSetFilter={(value: string) => G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps })}
        onKeyPressHandler={(value: string) => G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps })}
        inputProps={{
          fontSize,
          width: 120,
          height: 25,
          version: 2,
          p: '0 0 0 8px',
          borderColor: G.getTheme('colors.light.grey'),
        }}
      />
    );
  }

  if (R.equals(type, 'number')) {
    return (
      <QuickFilterOutsideControl
        withoutLabel={true}
        boxStyles={{ mb: '4px' }}
        allowSingleCharacter={true}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
        outsideValue={R.pathOr('', [GC.FIELD_NUMBER_VALUE], titleFilterValue)}
        handleSetFilter={(value: string) => G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps })}
        inputProps={{
          type,
          fontSize,
          width: 120,
          height: 25,
          version: 2,
          p: '0 0 0 8px',
          borderColor: G.getTheme('colors.light.grey'),
        }}
      />
    );
  }

  if (R.equals(type, 'boolean')) {
    const inputValue = R.pathOr('', [GC.FIELD_BOOLEAN_VALUE], titleFilterValue);

    const options = [
      { width: 30, loc: 'titles:yes', value: true },
      { width: 30, loc: 'titles:no', value: false },
      { width: 40, loc: 'actions:clean', value: '' },
    ];

    return (
      <Switcher
        mb='3px'
        options={options}
        value={inputValue}
        onSwitch={(value: any) => handleTableTitleFilter({ value, fieldProps })}
      />
    );
  }

  if (R.equals(type, 'reference')) {
    return (
      <QuickFilterOutsideControl
        withoutLabel={true}
        boxStyles={{ mb: '4px' }}
        placeholder={G.getWindowLocale('titles:search', 'Search')}
        outsideValue={R.pathOr('', [GC.FIELD_REFERENCE_VALUE], titleFilterValue)}
        handleSetFilter={(value: string) => G.callFunctionWithArgs(handleTableTitleFilter, { value, fieldProps })}
        inputProps={{
          fontSize,
          width: 120,
          height: 25,
          version: 2,
          p: '0 0 0 8px',
          borderColor: G.getTheme('colors.light.grey'),
        }}
      />
    );
  }

  return null;
};

export default TitleFilter;
