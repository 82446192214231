// constants
import * as GC from '../constants';
//////////////////////////////////////////////////

export const menuRoutesConfig = {
  [GC.MENU_ACTION_CONFIG_AUDIT]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_AUDIT],
  },
  [GC.MENU_ACTION_CONFIG_TASK_MANAGEMENT]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT],
  },
  [GC.MENU_ACTION_CONFIG_CLAIM_MANAGEMENT]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT],
  },
  [GC.MENU_ACTION_CONFIG_MASTER]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_OPTIONAL],
  },
  [GC.MENU_ACTION_CONFIG_FLEET_GENERAL]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_FLEET_GENERAL],
  },
  [GC.MENU_ACTION_CONFIG_SERVICE_VENDOR]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR],
  },
  [GC.MENU_ACTION_RATE_SHOP]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_RATE_SHOP],
  },
  [GC.MENU_ACTION_IMPORT_REPORT]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_IMPORT_REPORT],
  },
  [GC.MENU_ACTION_CHARGE_REPORTS]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT],
  },
  [GC.MENU_ACTION_DISPATCHING_GROUP]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_DISPATCH_GROUP],
  },
  [GC.MENU_ACTION_ROUTE_BUILDER]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_ROUTE_BUILDER],
  },
  [GC.MENU_ACTION_CONTAINER_TEMPLATE_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST],
  },
  [GC.MENU_ACTION_TEMPLATES_DO_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_TEMPLATES_DO_LIST],
  },
  [GC.MENU_ACTION_CARRIER_CONTRACT_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CARRIER_CONTRACTS],
  },
  [GC.MENU_ACTION_CUSTOMER_CONTRACT_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CUSTOMER_CONTRACTS],
  },
  [GC.MENU_ACTION_DRIVER_PAYROLL_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_DRIVER_PAYROLL_LIST],
  },
  [GC.MENU_ACTION_VENDOR_INVOICES_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_VENDOR_INVOICES_LIST],
  },
  [GC.MENU_ACTION_CUSTOMER_MASTER_INVOICE_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST],
  },
  [GC.MENU_ACTION_LOAD_PLANNER]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_LOAD_PLANNER],
  },
  [GC.MENU_ACTION_DRIVERS_CARD]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_DRIVERS_CARD],
  },
  [GC.MENU_ACTION_AVAILABLE_DRIVERS]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST],
  },
  [GC.MENU_ACTION_ROUTE_BETA]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_DISPATCH_BOARD_TEL, GC.ROUTE_PATH_DISPATCH_BOARD_CLO],
  },
  [GC.MENU_ACTION_TEL_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_TEL_REPORT_LIST],
  },
  [GC.MENU_ACTION_CLO_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CLO_REPORT_LIST],
  },
  [GC.MENU_ACTION_ROUTE_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_ROUTE_REPORT_LIST],
  },
  [GC.MENU_ACTION_BRANCH_LIST]: {
    regExps: [GC.ROUTE_PATH_BRANCH_EDIT_REGEXP],
    paths: [GC.ROUTE_PATH_BRANCH_LIST, GC.ROUTE_PATH_BRANCH_NEW],
  },
  [GC.MENU_ACTION_ROLES_LIST]: {
    regExps: [GC.ROUTE_PATH_ROLES_EDIT_REGEXP],
    paths: [GC.ROUTE_PATH_ROLES_LIST, GC.ROUTE_PATH_ROLES_NEW],
  },
  [GC.MENU_ACTION_USERS_LIST]: {
    regExps: [GC.ROUTE_PATH_USERS_SETING_REGEXP],
    paths: [GC.ROUTE_PATH_USERS_LIST, GC.ROUTE_PATH_USERS_NEW, GC.ROUTE_PATH_PROFILE],
  },
  [GC.MENU_ACTION_ITEMS_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_ITEMS_LIST],
  },
  [GC.MENU_ACTION_LOCATION_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_LOCATIONS_LIST],
  },
  [GC.MENU_ACTION_REFERENCE_TYPES_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_REFERENCES_LIST],
  },
  [GC.MENU_ACTION_SEQUENCE_LIST]: {
    regExps: [GC.ROUTE_PATH_SEQUENCES_LIST_REGEXP],
    paths: [GC.ROUTE_PATH_SEQUENCES_LIST],
  },
  [GC.MENU_ACTION_REPORT_FORMATS_LIST]: {
    regExps: [GC.ROUTE_PATH_REPORTS_EDIT_REGEXP],
    paths: [GC.ROUTE_PATH_REPORTS_LIST],
  },
  [GC.MENU_ACTION_FLEET_TRUCKS_LIST]: {
    regExps: [GC.ROUTE_PATH_FLEET_TRUCKS_EDIT_REGEXP],
    paths: [GC.ROUTE_PATH_FLEET_TRUCKS_LIST],
  },
  [GC.MENU_ACTION_FLEET_DRIVERS_LIST]: {
    paths: [GC.ROUTE_PATH_FLEET_DRIVERS_LIST],
    regExps: [GC.ROUTE_PATH_FLEET_DRIVERS_EDIT_REGEXP],
  },
  [GC.MENU_ACTION_FLEET_VENDOR_LIST]: {
    paths: [GC.ROUTE_PATH_FLEET_VENDOR_LIST],
    regExps: [GC.ROUTE_PATH_FLEET_VENDOR_EDIT_REGEXP],
  },
  [GC.MENU_ACTION_FLEET_TRAILERS_LIST]: {
    regExps: [GC.ROUTE_PATH_FLEET_TRAILERS_EDIT_REGEXP],
    paths: [GC.ROUTE_PATH_FLEET_TRAILERS_LIST],
  },
  [GC.MENU_ACTION_FLEET_EQUIPMENT_SERVICES_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST],
  },
  [GC.MENU_ACTION_FLEET_SHARED_COMPONENT_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST],
  },
  [GC.MENU_ACTION_ROUTE]: {
    regExps: [GC.ROUTE_PATH_CLO_LIST_REGEXP, GC.ROUTE_PATH_TEL_LIST_REGEXP],
    paths: [GC.ROUTE_PATH_CLO_LIST, GC.ROUTE_PATH_TEL_LIST],
  },
  [GC.MENU_ACTION_CHART_LIST]: {
    regExps: [GC.ROUTE_PATH_CHARTS_EDIT_REGEXP],
    paths: [GC.ROUTE_PATH_CHARTS_LIST, GC.ROUTE_PATH_CHARTS_NEW],
  },
  [GC.MENU_ACTION_CONFIG_CLO]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_CLO],
  },
  [GC.MENU_ACTION_CONFIG_TEL]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_TEL],
  },
  [GC.MENU_ACTION_CONFIG_PASSWORD]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_PASSWORD],
  },
  [GC.MENU_ACTION_CONFIG_GENERAL]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_GENERAL],
  },
  [GC.MENU_ACTION_CONFIG_TEMPLATES]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_TEMPLATES],
  },
  [GC.MENU_ACTION_CONFIG_TRUCK]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_TRUCK],
  },
  [GC.MENU_ACTION_CONFIG_DRIVER]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_DRIVER],
  },
  [GC.MENU_ACTION_CONFIG_TRAILER]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_TRAILER],
  },
  [GC.MENU_ACTION_CONFIG_COMMUNICATION]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_COMMUNICATION],
  },
  [GC.MENU_ACTION_CONFIG_RATING]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_RATING],
  },
  [GC.MENU_ACTION_CONFIG_INVOICES]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_INVOICES],
  },
  [GC.MENU_ACTION_CONFIG_CARRIER]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_CARRIER],
  },
  [GC.MENU_ACTION_CONFIG_INTEGRATION]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_INTEGRATION],
  },
  [GC.MENU_ACTION_CONFIG_MOBILEAPP]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_MOBILEAPP],
  },
  [GC.MENU_ACTION_CONFIG_UI]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONFIG_UI],
  },
  [GC.MENU_ACTION_DRIVER_INVOICES_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_DRIVER_INVOICES_LIST],
  },
  [GC.MENU_ACTION_CUSTOMER_INVOICES_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST],
  },
  [GC.MENU_ACTION_CARRIER_INVOICES_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CARRIER_INVOICES_LIST],
  },
  [GC.MENU_ACTION_SERVICE_VENDOR_INVOICE_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST],
  },
  [GC.MENU_ACTION_ANALYTIC_MAPS]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_FLEET_MAP],
  },
  [GC.MENU_ACTION_CUSTOMER_PORTAL]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CUSTOMER_PORTAL],
  },
  [GC.MENU_ACTION_ANALYTICS]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_ANALYTICS],
  },
  [GC.MENU_ACTION_DASHBOARDS]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_DASHBOARDS],
  },
  [GC.MENU_ACTION_CUSTOMER_MASTER_INVOICE_LIST]: {
    regExps: [],
    paths: [GC.MENU_ACTION_CUSTOMER_MASTER_INVOICE_LIST],
  },
  [GC.MENU_ACTION_CARRIER_LIST]: {
    regExps: [GC.ROUTE_PATH_CARRIERS_LIST_REGEXP],
    paths: [GC.ROUTE_PATH_CARRIERS_LIST],
  },
  [GC.MENU_ACTION_STYLING_CONFIG]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_STYLING, GC.ROUTE_PATH_SPLASH_SCREEN_SETTINGS],
  },
  [GC.MENU_ACTION_IMPORTS]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_IMPORT],
  },
  [GC.MENU_ACTION_EXTERNAL_LOADBOARD]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_LOAD_BOARD],
  },
  [GC.MENU_ACTION_FUEL_CARDS]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_FUEL_CARDS],
  },
  [GC.MENU_ACTION_CONTACT_BOOK]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CONTACT_BOOK],
  },
  [GC.MENU_ACTION_COMPENSATION_TEMPLATE_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST],
  },
  [GC.MENU_ACTION_SHARED_ACCESSORIAL_LIST]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_SHARED_ACCESSORIALS],
  },
  [GC.MENU_ACTION_GEO_FENCING_ZONE]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_GEO_FENCING_ZONE],
  },
  [GC.MENU_ACTION_GEO_FENCING_ZONE_REPORT]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT],
  },
  [GC.MENU_ACTION_AVERAGE_FUEL_PRICE]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_AVERAGE_FUEL_PRICE],
  },
  [GC.MENU_ACTION_TOLL_CHARGES]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_TOLL_CHARGES],
  },
  [GC.MENU_ACTION_IFTA_REPORT]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_IFTA_REPORT],
  },
  [GC.MENU_ACTION_CURRENCY_EXCHANGE_RATE]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST],
  },
  [GC.MENU_ACTION_ROUTE_TEMPLATES]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST],
  },
  [GC.MENU_ACTION_LOAD_PLANNER_TEMPLATE]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST],
  },
  [GC.MENU_ACTION_COMMISSION_ASSIGNEE]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_COMMISSION_ASSIGNMENT],
  },
  [GC.MENU_ACTION_CARRIER_RATING_REPORT]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CARRIER_RATING_REPORT],
  },
  [GC.MENU_ACTION_ROUTE_MASS_CREATE_REPORT]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT],
  },
  [GC.MENU_ACTION_DISPATCH_EDI]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_EDI_LIST],
  },
  [GC.MENU_ACTION_DISPATCH_CARRIER_EDI]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_CARRIER_EDI_LIST],
  },
  [GC.MENU_ACTION_DISPATCH_ORDER_API]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_PUBLIC_CLO_LIST],
  },
  [GC.MENU_ACTION_DISPATCH_ORDER_QUOTES]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_ORDER_QUOTES],
  },
  [GC.MENU_ACTION_DISPATCH_EXTERNAL_QUOTES]: {
    regExps: [],
    paths: [GC.ROUTE_PATH_QUOTE],
  },
};
