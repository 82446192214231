import * as Yup from 'yup';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const defaultFieldStyles = {
  width: '200px',
  errorTop: '110%',
  errorTitle: true,
  afterTop: '14px',
  errorLeft: '5px',
  labelWidth: '100%',
  errorWidth: '100%',
  flexDirection: 'column',
  errorOverflow: 'hidden',
  errorPosition: 'absolute',
  errorWhiteSpace: 'nowrap',
  errorTextOverflow: 'ellipsis',
};

const inputWrapperStyles = { mb: 25, width: 200 };

export const addAdvancePaymentFieldSettings = [
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:driver'],
    shouldCustomChange: true,
    fieldName: GC.FIELD_DRIVER_GUID,
    options: 'selectedRateDriverOptions',
    customDisabledFunction: 'disableSelectDriverField',
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:driver-first-name'],
    fieldName: GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:driver-last-name'],
    fieldName: GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:driver-number'],
    fieldName: GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:driver-id'],
    fieldName: GC.FIELD_ADVANCE_PAYMENT_FLEET_ID,
  },
  {
    type: 'select',
    inputWrapperStyles,
    label: ['titles:integration-type'],
    fieldName: GC.FIELD_ADVANCE_PAYMENT_INTEGRATION_TYPE,
    options: [
      {
        label: 'Comdata',
        value: GC.ADVANCE_PAYMENT_INTEGRATION_TYPE_COMDATA,
      },
    ],
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:trip-number'],
    fieldName: GC.FIELD_ADVANCE_PAYMENT_TRIP_NUMBER,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:check-amount'],
    fieldName: GC.FIELD_ADVANCE_PAYMENT_AMOUNT,
  },
  {
    type: 'text',
    inputWrapperStyles,
    label: ['titles:tag'],
    fieldName: GC.FIELD_ADVANCE_PAYMENT_TAG,
  },
  {
    type: 'textarea',
    label: ['titles:comments'],
    fieldName: GC.FIELD_COMMENTS,
    inputWrapperStyles: { mb: 25, width: 630 },
  },
];

export const defaultAddAdvancePaymentFields = {
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_DRIVER_GUID]: null,
  [GC.FIELD_ADVANCE_PAYMENT_TAG]: null,
  [GC.FIELD_ADVANCE_PAYMENT_AMOUNT]: null,
  [GC.FIELD_ADVANCE_PAYMENT_FLEET_ID]: null,
  [GC.FIELD_ADVANCE_PAYMENT_TRIP_NUMBER]: null,
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_NUMBER]: null,
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_LAST_NAME]: null,
  [GC.FIELD_ADVANCE_PAYMENT_DRIVER_FIRST_NAME]: null,
  [GC.FIELD_ADVANCE_PAYMENT_INTEGRATION_TYPE]: GC.ADVANCE_PAYMENT_INTEGRATION_TYPE_COMDATA,
};

export const addAdvancePaymentValidationSchemaObject = {
  [GC.FIELD_DRIVER_GUID]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
  [GC.FIELD_COMMENTS]: Yup.string().nullable(true).max(2000, G.getShouldBeLessOrEqualLocaleTxt(2000)),
  [GC.FIELD_ADVANCE_PAYMENT_TAG]: Yup.string().nullable(true).max(10, G.getShouldBeLessOrEqualLocaleTxt(10)),
  [GC.FIELD_ADVANCE_PAYMENT_AMOUNT]: Yup.number()
    .nullable(true)
    .required(G.getRequiredLocaleTxt())
    .positive(G.getShouldBePositiveLocaleTxt())
    .max(9999, G.getShouldBeLessOrEqualLocaleTxt(9999)),
};

export const updateAdvancePaymentStatusFieldSettings = [
  {
    type: 'select',
    options: 'editStatusOptions',
    label: ['titles:edit-status'],
    inputWrapperStyles: { mb: 25, width: 290 },
    fieldName: GC.FIELD_ADVANCE_PAYMENT_ACTION,
  },
  {
    type: 'text',
    label: ['titles:status-edit-reason'],
    inputWrapperStyles: { mb: 25, width: 290 },
    fieldName: GC.FIELD_ADVANCE_PAYMENT_REASON,
  },
];

export const defaultUpdateAdvancePaymentStatusFields = {
  [GC.FIELD_ADVANCE_PAYMENT_ACTION]: null,
  [GC.FIELD_ADVANCE_PAYMENT_REASON]: null,
};

export const updateAdvancePaymentStatusValidationSchema = {
  [GC.FIELD_ADVANCE_PAYMENT_REASON]: Yup.string().nullable(true),
  [GC.FIELD_ADVANCE_PAYMENT_ACTION]: Yup.string().nullable(true).required(G.getRequiredLocaleTxt()),
};
