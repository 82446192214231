import React from 'react';
import * as R from 'ramda';
// components
import { newReportConditions } from '../../../filter/settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { Checkbox } from '../../../../forms';
// icons
import * as I from '../../../../svgs';
// ui
import {
  Box,
  Span,
  Text,
  Flex,
  Divider,
  scrollableContainerCss3px,
} from '../../../../ui';
// component edit-report
import { renderField, FilterSelect } from './fields';
//////////////////////////////////////////////////

const FilterHeader = (props: Object) => {
  const {
    prompt,
    handlePrompt,
    disablePrompt,
    reportFilterGroups,
    handleAddReportGroup,
  } = props;

  const groupKeys = R.keys(reportFilterGroups);
  const groupsLength = R.length(groupKeys);

  return (
    <Flex
      p='0 15px'
      height={40}
      borderBottom='1px solid'
      justifyContent='space-between'
      color={G.getTheme('colors.light.black')}
      bg={G.getTheme('colors.light.lightGrey')}
      borderColor={G.getTheme('colors.lightGrey')}
    >
      <Span>{G.getWindowLocale('titles:filter', 'Filter')}</Span>
      {
        R.not(G.isTrue(disablePrompt)) &&
        <Flex ml='auto' zIndex={11}>
          <Box>{`${G.getWindowLocale('titles:prompt-values', 'Prompt values')}: `}</Box>
          <Box mr={10}>
            <Checkbox
              name='prompt'
              type='checkbox'
              checked={prompt}
              onChange={handlePrompt}
            />
          </Box>
        </Flex>
      }
      {
        R.lt(groupsLength, 5) &&
        <Box
          mx={10}
          fontSize={12}
          cursor='pointer'
          onClick={handleAddReportGroup}
          color={G.getTheme('colors.light.blue')}
        >
          {G.getWindowLocale('titles:add-multiple-matching-filters-or', 'Add Multiple matching filters(or)')}
        </Box>
      }
    </Flex>
  );
};

const FilterItemAction = (props: Object) => {
  const {
    groupId,
    filterIndex,
    handleRemoveReportFilter,
  } = props;

  return (
    <Flex>
      <Box>
        <Flex
          ml={10}
          cursor='pointer'
          alignItems='center'
          title={G.getWindowLocale('titles:remove', 'Remove')}
          onClick={() => handleRemoveReportFilter(groupId, filterIndex)}
        >
          {I.trash(G.getTheme('colors.dark.blue'))}
        </Flex>
      </Box>
    </Flex>
  );
};

const stringSelectPropertyNames = [
  GC.FIELD_ORIGIN_ZONE,
  GC.FIELD_LOCATION_ZONE,
  GC.FIELD_DESTINATION_ZONE,
];

export const PageFilterItem = (props: Object) => {
  const {
    groupId,
    dataType,
    timeUnit,
    operation,
    collection,
    filterIndex,
    dateRelative,
    propertyName,
    referenceName,
    withoutOrGroup,
    availableFields,
    filterFieldWidth,
    handleSelectOperation,
    handleSelectPropertyName,
  } = props;

  const operationToUse = G.ifElse(
    R.and(R.equals(dataType, 'date'), G.isTrue(dateRelative)),
    timeUnit,
    operation,
  );

  const propertyNameToUse = G.ifElse(
    R.equals(dataType, 'reference'),
    referenceName,
    propertyName,
  );

  const dataTypeToUse = G.ifElse(
    R.includes(propertyName, stringSelectPropertyNames),
    'string:select',
    dataType,
  );

  return (
    <Flex p='15px 6px 0' alignItems='center'>
      <FilterSelect
        groupId={groupId}
        collection={collection}
        value={propertyNameToUse}
        options={availableFields}
        filterIndex={filterIndex}
        withoutOrGroup={withoutOrGroup}
        action={handleSelectPropertyName}
        id={`filter-field-${filterIndex}`}
        filterFieldWidth={filterFieldWidth}
      />
      <FilterSelect
        groupId={groupId}
        value={operationToUse}
        dataType={dataTypeToUse}
        filterIndex={filterIndex}
        action={handleSelectOperation}
        filterFieldWidth={filterFieldWidth}
        id={`filter-operation-${filterIndex}`}
        options={G.getPropFromObject(R.or(dataTypeToUse, GC.FIELD_DEFAULT), newReportConditions())}
      />
      {
        G.isNotNilAndNotEmpty(operationToUse) &&
        renderField({ ...props, dataType: dataTypeToUse, id: `filter-value-${filterIndex}` })
      }
    </Flex>
  );
};

const FilterItem = (props: Object) => {
  const {
    groupId,
    dataType,
    timeUnit,
    operation,
    collection,
    groupLength,
    filterIndex,
    dateRelative,
    propertyName,
    referenceName,
    withoutOrGroup,
    availableFields,
    filterFieldWidth,
    useMenuPortalTarget,
    handleSelectOperation,
    handleSelectPropertyName,
  } = props;

  const operationToUse = G.ifElse(
    R.and(R.equals(dataType, 'date'), G.isTrue(dateRelative)),
    timeUnit,
    operation,
  );
  const propertyNameToUse = G.ifElse(
    R.equals(dataType, 'reference'),
    referenceName,
    propertyName,
  );
  const showDivider = G.notEquals(groupLength, R.inc(filterIndex));
  const dividerText = G.ifElse(
    G.isTrue(withoutOrGroup),
    G.getWindowLocale('titles:and', 'and', { caseAction: 'lowerCase' }),
    G.getWindowLocale('titles:or', 'or', { caseAction: 'lowerCase' }),
  );

  const dataTypeToUse = G.ifElse(
    R.includes(propertyName, stringSelectPropertyNames),
    'string:select',
    dataType,
  );

  return (
    <Box as={Box} width='100%'>
      <Flex mb={10} alignItems='center'>
        <FilterItemAction {...props} />
        <FilterSelect
          groupId={groupId}
          collection={collection}
          value={propertyNameToUse}
          options={availableFields}
          filterIndex={filterIndex}
          withoutOrGroup={withoutOrGroup}
          action={handleSelectPropertyName}
          id={`filter-field-${filterIndex}`}
          filterFieldWidth={filterFieldWidth}
          useMenuPortalTarget={useMenuPortalTarget}
        />
        <FilterSelect
          groupId={groupId}
          value={operationToUse}
          dataType={dataTypeToUse}
          filterIndex={filterIndex}
          action={handleSelectOperation}
          filterFieldWidth={filterFieldWidth}
          id={`filter-operation-${filterIndex}`}
          useMenuPortalTarget={useMenuPortalTarget}
          options={G.getPropFromObject(R.or(dataTypeToUse, GC.FIELD_DEFAULT), newReportConditions())}
        />
        {
          G.isNotNilAndNotEmpty(operationToUse) &&
          renderField({ ...props, dataType: dataTypeToUse, id: `filter-value-${filterIndex}` })
        }
      </Flex>
      { showDivider && <Divider text={dividerText} /> }
    </Box>
  );
};

const FilterGroupTitle = (props: Object) => {
  const { groupId, groupLength, handleAddReportFilter, reportSimpleFiltersGroupId } = props;

  const showAddFilter = R.lt(groupLength, 20);
  const withoutOrGroup = R.equals(groupId, reportSimpleFiltersGroupId);
  const title = G.ifElse(
    withoutOrGroup,
    G.getWindowLocale('titles:exact-matching-filters-and', 'Exact matching filters(and)'),
    G.getWindowLocale('titles:multiple-matching-filters-or', 'Multiple matching filters(or)'),
  );

  return (
    <Flex my={10} width='100%' justifyContent='center'>
      <Box fontSize={14} color={G.getTheme('colors.light.blue')}>
        {title}
      </Box>
      {
        showAddFilter &&
        <Box>
          <Flex
            ml={10}
            display='flex'
            cursor='pointer'
            alignItems='center'
            title={G.getWindowLocale('titles:add-filter', 'Add Filter')}
            onClick={() => handleAddReportFilter({ groupId, withoutOrGroup })}
          >
            {I.plusRound(G.getTheme('colors.dark.blue'))}
          </Flex>
        </Box>
      }
    </Flex>
  );
};

const FilterList = (props: Object) => {
  const {
    filterBy,
    useExactFilters,
    availableFields,
    hideFilterHeader,
    filterFieldWidth,
    handleChangeDate,
    handleChangeInput,
    handleChangeRange,
    reportFilterGroups,
    handleChangeSelect,
    useMenuPortalTarget,
    handleSelectOperation,
    filterFieldRangeWidth,
    handleAddReportFilter,
    handleChangeMultiSelect,
    handleSelectPropertyName,
    handleRemoveReportFilter,
    reportSimpleFiltersGroupId,
  } = props;

  const groupKeys = R.keys(reportFilterGroups);
  const showExactFiltersTitle = R.compose(
    R.not,
    R.any(R.propEq(true, 'withoutOrGroup')),
    R.reduce(R.concat, []),
    R.values,
  )(reportFilterGroups);

  return (
    <Box maxWidth={780} minWidth={G.ifElse(G.isTrue(useExactFilters), 620, 780)}>
      {R.not(hideFilterHeader) && <FilterHeader {...props} />}
      <Box
        width='auto'
        height='100%'
        overflowY='auto'
        maxHeight='calc(100vh - 225px)'
        css={scrollableContainerCss3px}
      >
        {
          G.isTrue(showExactFiltersTitle) &&
          <FilterGroupTitle
            groupLength={0}
            groupId={reportSimpleFiltersGroupId}
            handleAddReportFilter={handleAddReportFilter}
            reportSimpleFiltersGroupId={reportSimpleFiltersGroupId}
          />
        }
        {
          G.isNilOrEmpty(groupKeys) &&
          <Box p={15}>
            <Flex flexDirection='column'>
              <Text fontSize={15} color={G.getTheme('colors.dark.grey')}>
                {
                  G.getWindowLocale(
                    'messages:you-do-not-have-any-filters-for-this-report',
                    "You don't have any filters for this Report",
                  )
                }
              </Text>
            </Flex>
          </Box>
        }
        {
          groupKeys.map((groupId: string, groupIndex: index) => (
            <Flex
              py={10}
              key={groupId}
              alignItems='start'
              border='1px solid'
              flexDirection='column'
              borderColor={G.getTheme('colors.lightGrey')}
            >
              <FilterGroupTitle
                groupId={groupId}
                handleAddReportFilter={handleAddReportFilter}
                groupLength={R.length(reportFilterGroups[groupId])}
                reportSimpleFiltersGroupId={reportSimpleFiltersGroupId}
              />
              {
                reportFilterGroups[groupId].map((filter: Object, index: number) => (
                  <FilterItem
                    {...filter}
                    key={index}
                    filterBy={filterBy}
                    filterIndex={index}
                    groupIndex={groupIndex}
                    availableFields={availableFields}
                    filterFieldWidth={filterFieldWidth}
                    handleChangeDate={handleChangeDate}
                    handleChangeRange={handleChangeRange}
                    handleChangeInput={handleChangeInput}
                    handleChangeSelect={handleChangeSelect}
                    useMenuPortalTarget={useMenuPortalTarget}
                    filterFieldRangeWidth={filterFieldRangeWidth}
                    handleSelectOperation={handleSelectOperation}
                    handleChangeMultiSelect={handleChangeMultiSelect}
                    groupLength={R.length(reportFilterGroups[groupId])}
                    handleRemoveReportFilter={handleRemoveReportFilter}
                    handleSelectPropertyName={handleSelectPropertyName}
                    zIndex={G.curriedParseInt(R.subtract(R.divide(150, R.inc(groupIndex)), R.inc(index)))}
                  />
                ))
              }
            </Flex>
          ))
        }
      </Box>
    </Box>
  );
};

export default FilterList;
