import * as R from 'ramda';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import React, { useRef, useState, useEffect } from 'react';
// components
import { FormFooter2 } from '../form-footer';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

// TODO: create styled component after merge styled-components
const imageStyles = {
  marginLeft: 25,
  borderRadius: 4,
};

const getCroppedImg = (image: Object, crop: Object, fileName: string) => {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY,
  );

  return new Promise((resolve: Function) => {
    let fileUrl;

    canvas.toBlob(
      (blob: Object) => {
        if (R.not(blob)) return console.error('Canvas is empty');

        blob.name = fileName; // eslint-disable-line

        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);

        resolve(fileUrl);
      },
      'image/png',
      1,
    );
  });
};

const ImageCropper = (props: Object) => {
  const { file, submitAction } = props;

  const imageRef = useRef(null);
  const croppedImageRef = useRef(null);

  const [src, setSrc] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    height: 30,
  });

  const generateFile = () => {
    fetch(croppedImageUrl) // eslint-disable-line
      .then((res: Object) => res.blob())
      .then((blob: Object) => {
        const fileName = `${G.generateGuid()}.png`;
        const img = new File([blob], fileName, blob); // eslint-disable-line

        submitAction(img);
      });
  };

  useEffect(() => {
    const reader = new FileReader(); // eslint-disable-line

    reader.addEventListener('load', () => setSrc(reader.result));
    reader.readAsDataURL(file);
  }, [file]);

  const makeClientCrop = async (crop: Object) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(imageRef.current, crop, 'newFile.jpeg');

      setCroppedImageUrl(croppedImage);
    }
  };

  return (
    <Box minWidth={300}>
      <Flex>
        <ReactCrop
          src={src}
          crop={crop}
          onChange={setCrop}
          ruleOfThirds={true}
          onComplete={makeClientCrop}
          onImageLoaded={(image: Object) => imageRef.current = image}
          imageStyle={{
            minWidth: 300,
            minHeight: 150,
            maxWidth: 1000,
            maxHeight: 500,
          }}
        />
        <img
          alt='Crop'
          width={150}
          height={150}
          style={imageStyles}
          src={croppedImageUrl}
          ref={croppedImageRef}
        />
      </Flex>
      <FormFooter2 boxStyles={{ mt: 25 }} submitAction={() => generateFile()} />
    </Box>
  );
};

export default ImageCropper;
