import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle, withHandlers } from 'react-recompose';
// common
import {
  connectToMailIntegrationRequest,
  setConnectedToMailIntegrationEmails,
  disconnectFromMailIntegrationRequest,
} from '../../common/actions';
import {
  makeSelectMailIntegrationType,
  makeSelectConnectedToMailIntegrationEmails,
  makeSelectConnectionToMailIntegrationRequired,
} from '../../common/selectors';
// components
import { Label } from '../label';
import { ConfirmComponent } from '../confirm';
// features
import PC from '../../features/permission/role-permission';
// helpers
import * as G from '../../helpers';
import * as GC from '../../constants';
import { isValidEmail } from '../../helpers/validators';
// hocs
import { withConnectModalAndLoaderActions } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Flex, ActionButton } from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// connect-to-gmail
import ConnectToCustomMailForm from './connect-to-custom-mail-form';
//////////////////////////////////////////////////

const mapStateToProps = (state: Object) => createStructuredSelector({
  mailIntegrationType: makeSelectMailIntegrationType(state),
  connectedToMailIntegrationEmails: makeSelectConnectedToMailIntegrationEmails(state),
  connectionToMailIntegrationRequired: makeSelectConnectionToMailIntegrationRequired(state),
});

const enhance = compose(
  withConnectModalAndLoaderActions,
  connect(
    mapStateToProps,
    {
      connectToMailIntegrationRequest,
      setConnectedToMailIntegrationEmails,
      disconnectFromMailIntegrationRequest,
    },
  ),
  withHandlers({
    handleGetIsConnectedToGmail: (props: Object) => async () => {
      const {
        email,
        userGuid,
        branchGuid,
        connectedToMailIntegrationEmails,
        setConnectedToMailIntegrationEmails,
      } = props;

      if (G.hasNotAmousCurrentUserPermissions(PC.SEND_EMAIL_EXECUTE)) return;

      if (R.or(R.includes(email, R.keys(connectedToMailIntegrationEmails)), G.isNilOrEmpty(email))) return;

      const branchGuidToUse = R.or(branchGuid, G.getAmousCurrentBranchGuidFromWindow());

      const params = G.ifElse(
        G.isNotNilAndNotEmpty(userGuid),
        { userGuid },
        { email, [GC.BRANCH_GUID]: branchGuidToUse },
      );

      const res = await sendRequest('get', endpointsMap.isConnectedToMail, { params });

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        if (G.isNotNilAndNotEmpty(data)) {
          setConnectedToMailIntegrationEmails(R.assoc(email, R.head(data), connectedToMailIntegrationEmails));
        }
      } else {
        G.handleException('handleGetIsConnectedToGmail');
      }
    },
    handleConnectToCustomMail: (props: Object) => () => {
      const {
        userGuid,
        openModal,
        closeModal,
        configName,
        currentEmail,
        connectToMailIntegrationRequest,
      } = props;

      const submitAction = (values: Object) =>
        connectToMailIntegrationRequest(R.mergeRight({ userGuid, configName, [GC.FIELD_EMAIL]: currentEmail }, values));
      const component = <ConnectToCustomMailForm closeModal={closeModal} submitAction={submitAction} />;

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:connect', 'Connect'),
        },
      };

      openModal(modal);
    },
  }),
  withHandlers({
    handleConnect: (props: Object) => () => {
      const {
        userGuid,
        configName,
        currentEmail,
        mailIntegrationType,
        handleConnectToCustomMail,
        connectToMailIntegrationRequest,
      } = props;

      if (R.equals(mailIntegrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM)) {
        return handleConnectToCustomMail({ userGuid, [GC.FIELD_EMAIL]: currentEmail });
      }

      return connectToMailIntegrationRequest({ userGuid, configName, [GC.FIELD_EMAIL]: currentEmail });
    },
    handleDisconnect: (props: Object) => () => {
      const { email, openModal, connectedToMailIntegrationEmails, disconnectFromMailIntegrationRequest } = props;

      const component = (
        <ConfirmComponent
          name={email}
          textLocale={G.getWindowLocale('messages:before:disconnect', 'Are you sure you want to disconnect')}
        />
      );
      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:disconnect', 'Disconnect'),
              action: () =>
                disconnectFromMailIntegrationRequest(G.getPropFromObject(email, connectedToMailIntegrationEmails)),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleGetIsConnectedToGmail, shouldNotGetConnectedToGmailOnDidMount } = this.props;

      if (G.isTrue(shouldNotGetConnectedToGmailOnDidMount)) return;

      handleGetIsConnectedToGmail();
    },
    componentDidUpdate(prevProps: Object) {
      const { email, handleGetIsConnectedToGmail, shouldNotGetConnectedToGmailOnDidMount } = this.props;

      if (G.isAnyTrue(
        G.isNilOrEmpty(email),
        R.propEq(email, GC.FIELD_EMAIL, prevProps),
        G.isTrue(shouldNotGetConnectedToGmailOnDidMount),
      )) return;

      handleGetIsConnectedToGmail();
    },
  }),
);

export const ConnectToMailIntegration = enhance((props: Object) => {
  const {
    mr,
    ml,
    email,
    currentEmail,
    handleConnect,
    handleDisconnect,
    mailIntegrationType,
    connectedToMailIntegrationEmails,
    connectionToMailIntegrationRequired,
  } = props;

  if (G.isAllNilOrEmpty([email, currentEmail])) return null;

  if (R.and(
    G.isFalse(connectionToMailIntegrationRequired),
    G.notEquals(mailIntegrationType, GC.INTEGRATION_MAIL_CONFIG_TYPE_CUSTOM),
  )) return null;

  if (R.includes(email, R.keys(connectedToMailIntegrationEmails))) {
    return (
      <Flex ml={ml} mr={mr}>
        <Label fontSize={12} frontIcon={I.uiTrue()}>
          {G.getWindowLocale('titles:connected', 'Connected')}
        </Label>
        <ActionButton
          height={20}
          type='button'
          fontSize={12}
          minWidth='unset'
          borderRadius='3px'
          onClick={handleDisconnect}
        >
          {G.getWindowLocale('actions:disconnect', 'Disconnect')}
        </ActionButton>
      </Flex>
    );
  }

  if (isValidEmail(currentEmail)) {
    return (
      <ActionButton
        ml={ml}
        mr={mr}
        height={20}
        type='button'
        fontSize={12}
        minWidth='unset'
        borderRadius='3px'
        onClick={handleConnect}
      >
        {G.getWindowLocale('actions:connect', 'Connect')}
      </ActionButton>
    );
  }

  return null;
});
