import { createLogic } from 'redux-logic';
// components
import { closeAllModals } from '../components/modal/actions';
// features
import {
  sendLogOutRequest,
  receivedLogInSuccess,
  receivedNalLogInSuccess,
  receivedBoshLogInSuccess,
  receivedSessionValidationSuccess,
} from '../features/auth/actions';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const getExchangeRateMapByDate = async () => {
  try {
    const res = await sendRequest('get', endpointsMap.exchangeRateMapByDate);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.setItemToWindow('amousExchangeRateMapByDate', data);
    }
  } catch (error) {
    G.handleException('error', 'getExchangeRateMapByDate exception');
  }
};

const getExchangeRateMapByDateLogic = createLogic({
  warnTimeout: 0,
  cancelType: sendLogOutRequest,
  type: [
    receivedLogInSuccess,
    receivedNalLogInSuccess,
    receivedBoshLogInSuccess,
    receivedSessionValidationSuccess,
  ],
  process({ cancelled$ }: Object) {
    getExchangeRateMapByDate();

    const interval = setInterval(() => {
      getExchangeRateMapByDate();
    }, 3600000);

    cancelled$.subscribe(() => {
      clearInterval(interval);
    });
  },
});

const locationChangeLogic = createLogic({
  type: GC.LOCATION_CHANGE_ACTION,
  validate({ action }: Object, allow: void) {
    allow(action);
  },
  process(_: any, dispatch: void, done: void) {
    dispatch(closeAllModals());
    done();
  },
});

export default [locationChangeLogic, getExchangeRateMapByDateLogic];
