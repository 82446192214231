import React from 'react';
import * as R from 'ramda';
import { pure, compose, withHandlers } from 'react-recompose';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// ui
import { ReactSelect } from '../../ui';
// components additional-filters
import { getFilterTypeOptions } from './settings';
//////////////////////////////////////////////////

const TypeSelect = ({ handleChange, additionalFilterType, additionalFilterOptions }: Object) => (
  <ReactSelect
    onChange={handleChange}
    value={additionalFilterType}
    shouldNotGetValueFromOptions={true}
    options={R.or(additionalFilterOptions, getFilterTypeOptions())}
    placeholder={G.getWindowLocale('titles:tilter-type', 'Filter Type')}
    additionalStyles={{
      container: (baseStyles: Object) => ({
        ...baseStyles,
        width: 230,
        margin: '0 10px',
      }),
    }}
  />
);

const enhance = compose(
  withHandlers({
    handleChange: (props: Object) => (value: any) => {
      const {
        getAsyncGetUnitIdList,
        asyncDriverListFullName,
        setAdditionalFilterType,
        setAdditionalFilterValue,
        getBranchChildrenWithShared,
        getAsyncGetDriverListFullName,
        asyncBranchChildrenWithShared,
        additionalFiltersApplyHandler,
      } = props;

      setAdditionalFilterType(value, () => {
        const typeValue = R.path(['value'], value);

        setAdditionalFilterValue(null);

        if (R.isNil(value)) {
          additionalFiltersApplyHandler({
            additionalFilterType: null,
            additionalFilterValue: null,
          });
        }

        if (R.equals(typeValue, GC.ADDITIONAL_FILTER_TYPE_BRANCH)) {
          if (G.isNilOrEmpty(asyncBranchChildrenWithShared)) return getBranchChildrenWithShared();
        }

        if (R.equals(typeValue, GC.ADDITIONAL_FILTER_TYPE_DRIVER)) {
          if (G.isNilOrEmpty(asyncDriverListFullName)) return getAsyncGetDriverListFullName();
        }

        if (R.equals(typeValue, GC.ADDITIONAL_FILTER_TYPE_TRUCK)) {
          if (G.isNilOrEmpty(asyncDriverListFullName)) return getAsyncGetUnitIdList();
        }
      });
    },
  }),
  pure,
);

export default enhance(TypeSelect);
