import { createSelector } from 'reselect';
// helpers/constants
//////////////////////////////////////////////////

const selectGlobalStore = (state: Object) => state.global;

const makeSelectGoogleApiKey = () => createSelector(
  selectGlobalStore,
  (global: Object) => global.googleApiKey,
);

const makeSelectInitialSplashScreen = () => createSelector(
  selectGlobalStore,
  (global: Object) => global.initialSplashScreenSetting,
);

const makeSelectGlobal = () => createSelector(
  selectGlobalStore,
  (global: Object) => global,
);

const makeSelectSubmitLoading = () => createSelector(
  selectGlobalStore,
  ({ submitLoading }: Object) => submitLoading,
);

const makeSelectInitialDataLoaded = () => createSelector(
  selectGlobalStore,
  (global: Object) => global.initialDataLoaded,
);

const makeSelectInitialDataLoading = () => createSelector(
  selectGlobalStore,
  (global: Object) => global.initialDataLoading,
);

const makeSelectDocumentTemplates = () => createSelector(
  selectGlobalStore,
  ({ documentTemplates }: Object) => documentTemplates,
);

const makeSelectConnectedToMailIntegrationEmails = () => createSelector(
  selectGlobalStore,
  ({ connectedToMailIntegrationEmails }: Object) => connectedToMailIntegrationEmails,
);

const makeSelectConnectionToMailIntegrationRequired = () => createSelector(
  selectGlobalStore,
  ({ connectionToMailIntegrationRequired }: Object) => connectionToMailIntegrationRequired,
);

const makeSelectMailIntegrationType = () => createSelector(
  selectGlobalStore,
  ({ mailServiceIntegrationType }: Object) => mailServiceIntegrationType,
);

const makeSelectShowDriverOnboardingTab = () => createSelector(
  selectGlobalStore,
  ({ showDriverOnboardingTab }: Object) => showDriverOnboardingTab,
);

export {
  makeSelectGlobal,
  selectGlobalStore,
  makeSelectGoogleApiKey,
  makeSelectSubmitLoading,
  makeSelectDocumentTemplates,
  makeSelectInitialDataLoaded,
  makeSelectInitialDataLoading,
  makeSelectInitialSplashScreen,
  makeSelectMailIntegrationType,
  makeSelectShowDriverOnboardingTab,
  makeSelectConnectedToMailIntegrationEmails,
  makeSelectConnectionToMailIntegrationRequired,
};
