import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// ui
import { Box, RelativeFlex } from '../../ui';
//////////////////////////////////////////////////

export const getInitialOptions = (isResizable: boolean) => ({
  minWidth: 370,
  minHeight: 400,
  maxWidth: '94vw',
  maxHeight: '100%',
  disableDragging: true,
  enableResizing: { left: true },
  style: { pointerEvents: 'auto', cursor: null },
  default: { x: 0, y: 0, width: '40vw', height: '100%' },
  resizeHandleComponent: {
    left: (
      isResizable &&
      <RelativeFlex
        p='5px'
        top='50%'
        width={40}
        right={16}
        zIndex={1}
        height={50}
        cursor='move'
        borderRadius={15}
        justifyContent='center'
        transform='rotate(270deg)'
        bg={G.getTheme('colors.mainBlue')}
      >
        <Box transform='scaleX(-1)'>
          {I.moveIcon(G.getTheme('colors.white'), 22, 23)}
        </Box>
      </RelativeFlex>
    ),
  },
});

export const initialWrapperStyles = {
  top: '0px',
  zIndex: 20,
  right: '0px',
  width: '40vw',
  height: '100vh',
  position: 'fixed',
  disableEvents: true,
};
