import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const operationField = (value: any, form: Object, props: Object, fieldName: string) => {
  const path = fieldName.replace('operation', 'field');
  const operationValue = P.$get(fieldName, form);
  const fieldValue = P.$get(path, form);
  const condition = R.and(
    R.and(R.is(String, fieldValue), G.isNotEmpty(fieldValue)),
    R.and(R.is(String, operationValue), G.isNotEmpty(operationValue)),
  );
  if (condition) {
    return undefined;
  }
  if (R.and(R.is(String, fieldValue), G.isNotEmpty(fieldValue))) {
    props.touch(fieldName);

    return ' ';
  }
};

export const customRequired = (value: string, { summarySubgroup }: Object) => G.ifElse(
  R.and(G.isNotNilAndNotEmpty(summarySubgroup), R.isEmpty(value)),
  ' ',
  undefined,
);

export const customNumeric = (value: string) => (
  G.ifElse(G.isEmptyOrNumeric(value), ' ', undefined)
);
