import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import {
  Box,
  Flex,
  ActionButton,
  FormSaveButton,
  FormCancelButton,
  FormAdditionButton,
  FormSaveAsNewButton,
} from '../../ui';
//////////////////////////////////////////////////

const ButtonsBlock = ({
  buttons,
  saveAsNew,
  additionBtns,
  showSaveAsNew,
  saveAsNewBtnType,
}: Object) => (
  <Box>
    {
      R.equals(showSaveAsNew, true) &&
      <FormSaveAsNewButton
        p='8px 16px'
        m='10px 15px'
        width='120px'
        fontSize={14}
        onClick={saveAsNew}
        type={R.or(saveAsNewBtnType, 'submit')}
      >
        {G.getWindowLocale('actions:save-as-new', 'Save As New')}
      </FormSaveAsNewButton>
    }
    {
      additionBtns.map((button: Object, i: number) => (
        <FormAdditionButton
          key={i}
          p='8px 16px'
          m='10px 15px'
          fontSize={14}
          type={button.type}
          disabled={button.disabled}
          onClick={button.handleClick}
          width={R.or(button.width, 100)}
        >
          {button.btnText}
        </FormAdditionButton>
      ))
    }
    {
      buttons.map((button: Object, i: number) => (
        <ActionButton
          m={10}
          key={i}
          p='8px 16px'
          fontSize={14}
          onClick={button.action}
          width={R.or(button.width, 100)}
          type={R.or(button.type, 'submit')}
        >
          {button.btnText}
        </ActionButton>
      ))
    }
  </Box>
);

const SaveCancelBlock = ({
  save,
  width,
  formId,
  cancel,
  btnText,
  disabled,
  submitting,
  hideFormSaveBtn,
}: Object) => (
  <Box>
    <FormCancelButton
      p='8px 16px'
      m='10px 15px'
      width='100px'
      type='button'
      fontSize={14}
      onClick={cancel}
    >
      {G.getWindowLocale('actions:cancel', 'Cancel')}
    </FormCancelButton>
    {
      R.not(G.isTrue(hideFormSaveBtn)) &&
      <FormSaveButton
        m='10px 0'
        p='8px 16px'
        type='submit'
        fontSize={14}
        form={formId}
        onClick={save}
        width={R.or(width, 100)}
        disabled={R.or(disabled, submitting)}
        cursor={G.ifElse(R.or(disabled, submitting), 'not-allowed', 'pointer')}
      >
        {R.or(btnText, G.getWindowLocale('actions:save', 'Save'))}
      </FormSaveButton>
    }
  </Box>
);

export const FormButtons = (props: Object) => {
  const {
    pl,
    pr,
    style,
    width,
    zIndex,
    formId,
    btnText,
    submitting,
    saveDisabled,
    showSaveAsNew,
    hideFormSaveBtn,
    handleClickSave,
    saveAsNewBtnType,
    handleClickCancel,
    handleClickSaveAsNew,
  } = props;

  return (
    <Flex
      left='0px'
      height={60}
      bottom='0px'
      width='100%'
      style={style}
      position='fixed'
      pl={R.or(pl, 60)}
      pr={R.or(pr, 15)}
      borderTop='1px solid'
      zIndex={R.or(zIndex, 12)}
      transition='all .2s linear'
      justifyContent='space-between'
      bg={G.getTheme('forms.actionsFooter.bgColor')}
      borderColor={G.getTheme('forms.actionsFooter.borderTopColor')}
    >
      <ButtonsBlock
        showSaveAsNew={showSaveAsNew}
        saveAsNew={handleClickSaveAsNew}
        saveAsNewBtnType={saveAsNewBtnType}
        buttons={R.pathOr([], ['buttons'], props)}
        additionBtns={R.pathOr([], ['additionBtns'], props)}
      />
      <SaveCancelBlock
        width={width}
        formId={formId}
        btnText={btnText}
        save={handleClickSave}
        submitting={submitting}
        disabled={saveDisabled}
        cancel={handleClickCancel}
        hideFormSaveBtn={hideFormSaveBtn}
      />
    </Flex>
  );
};
