import * as R from 'ramda';
import React from 'react';
import { pure, compose, withProps, withState, withHandlers, withPropsOnChange } from 'react-recompose';
// components
import { MuiTextInput } from '../mui-text-input';
// features
import { Input as Input2 } from '../../features/new-do/ui';
import { InputWrapper } from '../../features/new-do/forms/formik/input-wrapper';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// forms
import { Input } from '../../forms';
// ui
import { Box, Flex } from '../../ui';
/////////////////////////////////////////////////

const enhanceOutsideControl = withPropsOnChange(['outsideValue'], (props: Object) => {
  const { filterValue, outsideValue, setFilterValue } = props;

  if (G.notEquals(filterValue, outsideValue)) {
    setFilterValue(outsideValue);
  }
});

let timeout;

const enhance = compose(
  withProps(() => ({ id: G.genShortId() })),
  withState('filterValue', 'setFilterValue', ''),
  withHandlers({
    handleOnChange: ({
      id,
      noBlur,
      delayTime,
      handleSetFilter,
      allowSingleCharacter,
      allowSetAdditionalFiltersImmediately,
    }: Object) => (e: Object) => {
      const value = e.target.value;

      clearTimeout(timeout);

      if (allowSetAdditionalFiltersImmediately) {
        handleSetFilter(value);

        return;
      }

      if (R.or(allowSingleCharacter, G.notEquals(value.length, 1))) {
        timeout = setTimeout(() => {
          const el = document.getElementById(id);

          if (R.and(
            R.not(noBlur),
            G.isNotNil(el),
          )) el.blur();

          handleSetFilter(value);
        }, R.or(delayTime, 2500));
      }
    },
  }),
  pure,
);

const onKeyPressHandler = (props: Object, e: Object) => {
  const { keyCode, charCode } = e;
  const { value } = e.target;
  const code = R.or(keyCode, charCode);
  const { id, onKeyPressHandler, shouldClearTimeoutOnKeyPress } = props;

  if (R.and(R.equals(code, GC.EVENT_KEY_CODE_ENTER), G.isFunction(onKeyPressHandler))) {
    if (shouldClearTimeoutOnKeyPress) clearTimeout(timeout);

    const el = document.getElementById(id);

    if (G.isNotNil(el)) el.blur();

    onKeyPressHandler(value);
  }
};

const Component = (props: Object) => (
  <Flex {...R.pathOr({}, ['boxStyles'], props)}>
    {
      R.not(props.withoutLabel) &&
      <Box mr={10}>
        {R.or(props.label, G.getWindowLocale('titles:filter', 'Filter'))}
      </Box>
    }
    <Input
      {...R.pathOr({}, ['inputProps'], props)}
      id={props.id}
      value={props.filterValue}
      onKeyPress={(e: Object) => onKeyPressHandler(props, e)}
      width={R.pathOr('150px', ['inputProps', 'width'], props)}
      placeholder={R.or(props.placeholder, G.getWindowLocale('titles:filter', 'Filter'))}
      onChange={(e: Object) => {
        props.handleOnChange(e);
        props.setFilterValue(e.target.value);
      }}
    />
    {props.endLabel}
  </Flex>
);

export const Component2 = (props: Object) => {
  const {
    label,
    error,
    errorTop,
    hasError,
    isRequired,
    width = 80,
    endIconName,
    placeholder,
    outsideValue,
    handleOnChange,
    setFilterValue,
    inputWrapperStyles,
  } = props;

  const wrapperProps = {
    width,
    error,
    label,
    errorTop,
    hasError,
    isRequired,
    endIconName,
    inputWrapperStyles,
  };

  return (
    <InputWrapper {...wrapperProps}>
      <Input2
        pl={10}
        pr={18}
        width={width}
        value={outsideValue}
        placeholder={placeholder}
        onKeyPress={(e: Object) => onKeyPressHandler(props, e)}
        onChange={(e: Object) => {
          handleOnChange(e);
          setFilterValue(e.target.value);
        }}
      />
    </InputWrapper>
  );
};

export const Component3 = (props: Object) => {
  const {
    label,
    error,
    errorTop,
    hasError,
    isRequired,
    width = 80,
    placeholder,
    endIconName,
    outsideValue,
    handleSetFilter,
    inputWrapperStyles,
  } = props;

  const wrapperProps = {
    width,
    error,
    label,
    errorTop,
    hasError,
    isRequired,
    endIconName,
    inputWrapperStyles,
  };

  return (
    <InputWrapper {...wrapperProps}>
      <Input2
        pl={10}
        pr={18}
        width={width}
        fontSize={12}
        value={outsideValue}
        placeholder={placeholder}
        onChange={(e: Object) => handleSetFilter(e.target.value)}
      />
    </InputWrapper>
  );
};

export const Component4 = (props: Object) => {
  const {
    type,
    label,
    inputStyles,
    placeholder,
    filterValue,
    handleOnChange,
    setFilterValue,
  } = props;

  return (
    <MuiTextInput
      type={type}
      label={label}
      value={filterValue}
      inputStyles={inputStyles}
      placeholder={placeholder}
      onKeyPress={(e: Object) => onKeyPressHandler(props, e)}
      onChange={(e: Object) => {
        handleOnChange(e);
        setFilterValue(G.getEventTargetValue(e));
      }}
    />
  );
};

export const QuickFilter = enhance(Component);

export const QuickFilter2 = enhance(Component2);

export const QuickFilterOutsideControl = enhance(enhanceOutsideControl(Component));

export const QuickFilterOutsideControl2 = enhance(enhanceOutsideControl(Component3));

export const QuickFilterOutsideControl3 = enhance(enhanceOutsideControl(Component4));
