import * as R from 'ramda';
import React from 'react';
import {
  compose,
  withState,
  withHandlers,
  withPropsOnChange } from 'react-recompose';
// features
import { AuthWrapper } from '../../features/permission';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
// forms
import { Textarea } from '../../forms/ui';
// component chat
import {
  ChatAction,
  MessageBox,
  ChatHeader,
  NameSection,
  ChatUserBox,
  ChatWrapper,
  ChatContent,
  ChatUserBoxPhoto,
  ScrollableWrapper,
  MessageBoxWrapper,
  ChatMessageWrapper,
  ChatUserBoxInitials } from './ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('message', 'setMessage', undefined),
  withState('scrollDown', 'setScrollDown', true),
  withHandlers(() => {
    const refs = {};
    return {
      handleAddMessage: (props: Object) => () => {
        if (R.or(G.isNotNilAndNotEmpty(props.message), G.isNotNilAndNotEmpty(props.chunks))) {
          props.addNewMessageAction({ message: props.message });
          props.setMessage('');
          props.setScrollDown(true);
        }
      },
      handleChangeMessage: (props: Object) => (e: string) => {
        props.setMessage(e.currentTarget.value);
      },
      handleScrollWrapper: () => () => {
        const height = R.path(['scrollableWrapper', 'scrollHeight'], refs);
        if (G.isNotNilAndNotEmpty(height)) {
          setTimeout(() => {
            if (G.isNotNil(refs.scrollableWrapper)) refs.scrollableWrapper.scrollTop = height;
          }, 0);
        }
      },
      handleSetRefs: (props: Object) => (ref: Object, name: string) => {
        refs[name] = ref;
        const condition = R.and(
          G.isTrue(props.scrollDown),
          G.isNotNil(refs.scrollableWrapper),
        );
        if (condition) {
          refs.scrollableWrapper.scrollTop = refs.scrollableWrapper.scrollHeight;
          const scrolled = R.or(
            R.equals(refs.scrollableWrapper.scrollHeight, 0),
            G.notEquals(refs.scrollableWrapper.scrollTop, 0),
          );
          if (scrolled) props.setScrollDown(false);
        }
      },
    };
  }),
  withPropsOnChange(['chatMessages'], (props: Object) => props.handleScrollWrapper()),
);

const renderUserInfo = (messageObj: Object) => {
  if (R.or(messageObj.authorFirstName, messageObj.authorLastName)) {
    return `${R.pathOr('', ['authorFirstName', 0], messageObj)}${R.pathOr('', ['authorLastName', 0], messageObj)}`;
  }
  return R.path(['createdBy', 0], messageObj);
};

const UserBox = ({ messageItem, styleOptions }: Object) => {
  if (G.isNotNil(messageItem.photo)) {
    return (
      <ChatUserBox mt='15px' positionSide={styleOptions.userPosition}>
        <ChatUserBoxPhoto bgImage={messageItem.photo} />
      </ChatUserBox>
    );
  }
  return (
    <ChatUserBox
      mt={15}
      positionSide={styleOptions.userPosition}
    >
      <ChatUserBoxInitials
        color={styleOptions.userColor}
        borderColor={styleOptions.userColor}
      >
        {renderUserInfo(messageItem)}
      </ChatUserBoxInitials>
    </ChatUserBox>
  );
};

const Message = ({ messageItem, styleOptions }: Object) => {
  if (G.isNilOrEmpty(messageItem.text)) return null;
  return (
    <div>
      <MessageBoxWrapper jc={styleOptions.jc} p={styleOptions.padding}>
        <NameSection jc={styleOptions.jc}>
          {
            G.ifElse(
              R.or(messageItem.authorFirstName, messageItem.authorLastName),
              `${R.or(messageItem.authorFirstName, '')} ${R.or(messageItem.authorLastName, '')}`,
              messageItem.createdBy,
            )
          }
        </NameSection>
        <UserBox messageItem={messageItem} styleOptions={styleOptions} />
        <MessageBox color={styleOptions.textColor} bgColor={styleOptions.messageBgColor}>
          {messageItem.text}
        </MessageBox>
      </MessageBoxWrapper>
    </div>
  );
};

const getMessageStyleOptions = (messageItem: Object, currentUserGuid: string) => {
  if (R.equals(messageItem.authorUserGuid, currentUserGuid)) {
    return {
      jc: 'flex-end',
      userPosition: 'right',
      padding: '5px 40px 5px 20px',
      userColor: G.getTheme('chat.userBoxColor'),
      textColor: G.getTheme('chat.userMessageColor'),
      messageBgColor: G.getTheme('chat.userMessageBgColor'),
    };
  }
  return {
    jc: 'flex-start',
    userPosition: 'left',
    padding: '5px 20px 5px 40px',
    userColor: G.getTheme('chat.messageColor'),
    textColor: G.getTheme('chat.messageColor'),
    messageBgColor: G.getTheme('chat.messageBgColor'),
  };
};

const renderMessages = (props: Object) => {
  if (R.or(R.isNil(props.chatMessages), R.not(G.isArray(props.chatMessages)))) return;
  return (
    <ChatContent>
      <ScrollableWrapper ref={(ref: Object) => props.handleSetRefs(ref, 'scrollableWrapper')}>
        {
          props.chatMessages.map(
            (messageItem: Object, index: number) => (
              <Message
                key={index}
                messageItem={messageItem}
                styleOptions={getMessageStyleOptions(messageItem, props)} />
            ),
          )
        }
      </ScrollableWrapper>
    </ChatContent>);
};

export const ChatComponent = enhance((props: Object) => {
  const sendMessageColor = G.getTheme('chat.sendMessageColor');
  const sendMessageBgColor = G.getTheme('chat.sendMessageBgColor');
  return (
    <ChatWrapper {...props}>
      <ChatHeader bgColor={props.headerBgColor} color={props.headerColor}>
        {G.getWindowLocale('titles:chat', 'Chat')}
      </ChatHeader>
      {renderMessages(props)}
      <AuthWrapper has={props.textareaPermissions}>
        <ChatMessageWrapper p='8px 50px 8px 8px'>
          <Textarea
            fontSize={15}
            value={props.message}
            onChange={props.handleChangeMessage}
            placeholder={G.getWindowLocale('titles:add-message-here', 'Add your message here…')} />
          <ChatAction right='5px' onClick={props.handleAddMessage}>
            {
              I.renderSendMessageIconFullRed(
                sendMessageColor,
                null,
                null,
                sendMessageBgColor,
              )
            }
          </ChatAction>
        </ChatMessageWrapper>
      </AuthWrapper>
    </ChatWrapper>
  );
});

export default ChatComponent;
