import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withProps,
  withHandlers,
  renderNothing,
} from 'react-recompose';
// components
import { getConfirmModal } from '../confirm';
import { closeModal, openModal } from '../modal/actions';
// features
import { makeSelectRoleList } from '../../features/role/selectors';
import { makeSelectAllAvailableReferenceTypesByScopeName } from '../../features/reference/selectors';
import { columnSettings as payrollColumnSettings } from '../../features/payroll/settings/payroll-list-table-settings';
import { loadColumnSettings as dispatchColumnSettings } from '../../features/dispatch-report/settings/column-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// component edit-report
import { OutsideReportExactFilterList } from './';
import { ReportForm } from './components/report-form';
import { buildPivotReport, isNotReportValid, buildPivotComplexReport } from './helpers';
/////////////////////////////////////////////////

// TODO: common hocs with index

const getColumnSettings = ({ type }: Object) => R.pathOr({}, [type], {
  [GC.PIVOT_CLO_REPORT]: dispatchColumnSettings,
  [GC.PIVOT_TEL_REPORT]: dispatchColumnSettings,
  [GC.PIVOT_DRIVER_PAYROLL_REPORT]: payrollColumnSettings,
  [GC.PIVOT_VENDOR_PAYROLL_REPORT]: payrollColumnSettings,
});

const setEditReportFormProps = ({ selectedReport }: Object) => {
  if (G.isNilOrEmpty(selectedReport)) {
    return {
      initialValues: {},
    };
  }

  const { name, roleGuids, description, defaultReport } = selectedReport;

  const initialValues = {
    name,
    roleGuids,
    description,
    defaultReport,
  };

  return {
    initialValues,
  };
};

const reportEnhancer = compose(
  withHandlers({
    handleCreatePivotReport: (props: Object) => (values: Object) => {
      const { closeModal, selectedReport, createReportRequest } = props;

      const columnSettings = getColumnSettings(selectedReport);

      const newReport = {
        ...buildPivotReport(props, columnSettings, true),
        ...values,
      };

      if (isNotReportValid(newReport)) return;

      createReportRequest(newReport);

      closeModal();
    },
    handleCreatePivotComplexReport: (props: Object) => (values: Object) => {
      const { closeModal, selectedReport, createReportRequest } = props;

      const columnSettings = getColumnSettings(selectedReport);
      const complexReport = flexMonsterPivotInstance.getReport(); // eslint-disable-line

      const newReport = {
        ...buildPivotComplexReport(props, columnSettings, true),
        ...values,
      };

      if (isNotReportValid(newReport)) return;

      createReportRequest(newReport);

      closeModal();
    },
    handleUpdatePivotReport: (props: Object) => (values: Object) => {
      const { closeModal, selectedReport, updateReportRequest } = props;

      const columnSettings = getColumnSettings(selectedReport);

      const newReport = {
        ...buildPivotReport(props, columnSettings),
        ...values,
      };

      if (isNotReportValid(newReport)) return;

      updateReportRequest(newReport);

      closeModal();
    },
    handleUpdatePivotComplexReport: (props: Object) => (values: Object) => {
      const { closeModal, selectedReport, updateReportRequest } = props;

      const columnSettings = getColumnSettings(selectedReport);
      const complexReport = flexMonsterPivotInstance.getReport(); // eslint-disable-line

      const newReport = {
        ...buildPivotComplexReport(props, columnSettings),
        ...values,
      };

      if (isNotReportValid(newReport)) return;

      updateReportRequest(newReport);

      closeModal();
    },
  }),
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  roleList: makeSelectRoleList(state),
});

export const enhancePivotReport = compose(
  connect(mapStateToProps, { openModal, closeModal }),
  withProps((props: Object) => setEditReportFormProps(props)),
  reportEnhancer,
  withHandlers({
    handleClickSavePivot: (props: Object) => () => {
      const {
        roleList,
        pivotType,
        openModal,
        initialValues,
        handleUpdatePivotReport,
        handleUpdatePivotComplexReport,
      } = props;

      const updateAction = G.ifElse(
        R.equals(pivotType, 'simple'),
        handleUpdatePivotReport,
        handleUpdatePivotComplexReport,
      );

      const component = (
        <ReportForm
          roles={R.values(roleList)}
          submitAction={updateAction}
          initialValues={initialValues}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          overflow: 'auto',
          maxHeight: '80vh',
          title: G.getWindowLocale('actions:edit-report', 'Edit Report'),
        },
      };

      openModal(modal);
    },
    handleClickSaveAsNewPivot: (props: Object) => () => {
      const {
        roleList,
        pivotType,
        openModal,
        handleCreatePivotReport,
        handleCreatePivotComplexReport,
      } = props;

      const createAction = G.ifElse(
        R.equals(pivotType, 'simple'),
        handleCreatePivotReport,
        handleCreatePivotComplexReport,
      );

      const component = <ReportForm roles={R.values(roleList)} submitAction={createAction} />;

      const modal = {
        p: 15,
        component,
        options: {
          width: 400,
          overflow: 'auto',
          maxHeight: '80vh',
          title: G.getWindowLocale('titles:create-new-report', 'Create New Report'),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const reportTypesMap = {
  // fleet reports
  [GC.ROUTE_PATH_FLEET_TRUCKS_LIST]: GC.FLEET_TRUCK_REPORT,
  [GC.ROUTE_PATH_FLEET_DRIVERS_LIST]: GC.FLEET_DRIVER_REPORT,
  [GC.ROUTE_PATH_FLEET_TRAILERS_LIST]: GC.FLEET_TRAILER_REPORT,
  [GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST]: GC.FLEET_SHARED_COMPONENT_REPORT,
  [GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST]: GC.FLEET_EQUIPMENT_SERVICE_REPORT,
  // template reports
  [GC.ROUTE_PATH_ITEMS_LIST]: GC.ITEM_REPORT,
  [GC.ROUTE_PATH_CONTACT_BOOK]: GC.CONTACT_BOOK_REPORT,
  [GC.ROUTE_PATH_TEMPLATES_DO_LIST]: GC.CLO_TEMPLATE_REPORT,
  [GC.ROUTE_PATH_LOCATIONS_LIST]: GC.LOCATION_TEMPLATE_REPORT,
  [GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST]: GC.ROUTE_TEMPLATE_REPORT,
  [GC.ROUTE_PATH_SHARED_ACCESSORIALS]: GC.SHARED_ACCESSORIAL_REPORT,
  [GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST]: GC.CONTAINER_TEMPLATE_REPORT,
  [GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST]: GC.COMPENSATION_TEMPLATE_REPORT,
};

const promptMapPropsToValues = (state: Object) => createStructuredSelector({
  allAvailableRefTypesByScopeName: makeSelectAllAvailableReferenceTypesByScopeName(state),
});

export const withPromptModal = (settings: Array = []) => compose(
  connect(promptMapPropsToValues),
  withState('openPromptModal', 'setOpenPromptModal', false),
  withHandlers({
    handleOpenPromptModal: (props: Object) => () => {
      const {
        openModal,
        closeModal,
        filterProps,
        selectedReport,
        setOpenPromptModal,
        allAvailableRefTypesByScopeName,
      } = props;

      const reportType = R.path(['selectedReport', GC.FIELD_TYPE], props);

      const filterPropsToUse = G.ifElse(G.isObject(filterProps), R.values(filterProps), filterProps);

      const entityParams = G.ifElse(
        R.and(G.isArray(filterPropsToUse), G.isNotNilAndNotEmpty(filterPropsToUse)),
        filterPropsToUse,
        settings,
      );

      const availableFields = G.setFilterByParams(
        G.getRefListByReportType(allAvailableRefTypesByScopeName, reportType),
        entityParams,
        { reportType },
      );

      const filterFields = R.pathOr([], ['searchCriteria'], selectedReport);

      const closePopper = () => {
        closeModal();
        setOpenPromptModal(false);
      };

      const component = (
        <OutsideReportExactFilterList
          {...props}
          prompt={true}
          filterFieldWidth={150}
          useExactFilters={true}
          hideFilterHeader={true}
          closePopper={closePopper}
          useMenuPortalTarget={true}
          filterFields={filterFields}
          usedReport={selectedReport}
          availableFields={availableFields}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('actions:report-parameters', 'Report Parameters'),
        },
      };

      openModal(modal);

      setOpenPromptModal(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match,
        reportType,
        pageVisited,
        selectedReport,
        openPromptModal,
        setOpenPromptModal,
        handleOpenPromptModal,
      } = this.props;

      if (G.isNilOrEmpty(selectedReport)) return;

      if (G.isNotNilAndNotEmpty(reportType)) {
        const currentPath = R.pathOr('', ['path'], match);
        const selectedReportType = R.pathOr('', ['type'], selectedReport);

        if (G.notEquals(R.path([currentPath], reportTypesMap), selectedReportType)) return;
      }

      const { prompt, defaultReport } = selectedReport;

      const isDefaultReport = G.ifElse(G.isNilOrEmpty(pageVisited), G.isTrue(defaultReport), true);

      if (G.isAllTrue(G.isTrue(prompt), G.isFalse(openPromptModal), G.isTrue(isDefaultReport))) {
        handleOpenPromptModal();

        setOpenPromptModal(true);
      }
    },
    componentDidUpdate(prevProps: Object) {
      const { openPromptModal, setOpenPromptModal, handleOpenPromptModal } = this.props;

      const selectedReport = R.pathOr({}, ['selectedReport'], this.props);
      const prompt = G.getPropFromObject(GC.FIELD_PROMPT, selectedReport);
      const prevSelectedReport = R.pathOr({}, ['selectedReport'], prevProps);

      if (R.and(
        G.isNotNilAndNotEmpty(selectedReport),
        G.checkPropNotEqualInObjects(GC.FIELD_GUID, selectedReport, prevSelectedReport),
      )) {
        if (R.and(G.isTrue(prompt), G.isFalse(openPromptModal))) {
          handleOpenPromptModal();

          setOpenPromptModal(true);
        }
      }
    },
  }),
  pure,
);

const deleteConfirmationLocaleTxt = G.getWindowLocale(
  'messages:delete-confirmation-text-double',
  'Are you sure you want to delete',
);

export const getEnhanceReport1 = (params: any) => compose(
  withFixedPopover,
  withState('originalReport', 'setOriginalReport', null),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withHandlers({
    handleListRequest: ({ getItemListRequest }: Object) => (isInitial: boolean = false) => (
      getItemListRequest(isInitial)
    ),
    getQuickFilteredListRequest: (props: Object) => () => {
      const { getItemListRequest, resetListAndPagination } = props;

      resetListAndPagination();
      getItemListRequest(true);
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { reportList, setUsedReport, getItemListRequest } = props;

      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);

      setUsedReport(selectedReport);
      getItemListRequest(true);
    },
    handleChangeReportParams: ({ setReportFromPrompt }: Object) => (data: Object) => (
      setReportFromPrompt(data)
    ),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getItemListRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getItemListRequest(true);
      }

      setPromptStatus(false);
    },
    handleCleanFilter: (props: Object) => () => {
      const { cleanQuickFilter, getItemListRequest } = props;

      cleanQuickFilter();
      getItemListRequest(true);
    },
    handleDeleteItems: (props: Object) => () => {
      const { itemList, openModal, closeModal, deleteItemRequest } = props;

      const selectedList = R.filter(
        R.prop('selected'),
        itemList,
      );

      if (R.propEq(0, 'length', selectedList)) return;

      const locale = R.pathOr(G.getWindowLocale('titles:items', 'Item(s)'), ['locale'], params);

      const modalContent = getConfirmModal({
        cancelAction: closeModal,
        text: deleteConfirmationLocaleTxt,
        cancelText: G.getWindowLocale('actions:cancel', 'Cancel'),
        submitText: G.getWindowLocale('actions:confirm', 'Confirm'),
        name: `${R.length(selectedList)} ${locale}`,
        submitAction: () => {
          deleteItemRequest(R.map(
            (item: Object) => G.getGuidFromObject(item),
            selectedList,
          ));

          closeModal();
        },
      });

      openModal(modalContent);
    },
    handleTableTitleFilter: G.handleTableTitleFilter,
  }),
  pure,
);

export const enhanceEndReport = compose(
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  lifecycle({
    componentWillUnmount() {
      const {
        setUsedReport,
        originalReport,
        selectedReport,
      } = this.props;

      if (R.equals(
        R.path([GC.FIELD_GUID], originalReport),
        R.path([GC.FIELD_GUID], selectedReport))
      ) setUsedReport(originalReport);
    },
  }),
  pure,
);
