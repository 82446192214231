const commonPopperSettings = (color: string) => ({
  type: 'click',
  overflow: 'unset',
  borderColor: color,
  borderWidth: '1px solid',
  position: 'bottom-start',
  wrapperWidth: 'max-content',
  popperComponentWrapperMargin: '0 0 0 50px',
});

const commonPopperContainerSettings = (color: string) => ({
  px: 20,
  background: color,
  borderRadius: '3px',
});

const commonTextSettings = ({ mainBlue, mainLight }: Object) => ({
  py: '7px',
  color: mainLight,
  background: mainBlue,
  textTransform: 'uppercase',
});

const commonIconSettings = {
  ml: '5px',
};

const commonFilterIconSettings = (color: string) => ({
  iconColor: color,
});

export default (props: Object) => (
  {
    popper: {
      common: commonPopperSettings(props.mainBlue),
      imports: {
        ...commonPopperSettings(props.mainBlue),
        popperZIndex: 13,
        popperComponentWrapperMargin: '0 0 0 20px',
      },
      importNumberOfRows: {
        ...commonPopperSettings(props.mainBlue),
        popperZIndex: 13,
        popperComponentWrapperMargin: '0',
      },
      userPermissions: {
        ...commonPopperSettings(props.mainBlue),
        popperZIndex: 13,
        popperComponentWrapperMargin: '8px 0 0 15px',
      },
    },
    popperContainer: {
      common: commonPopperContainerSettings(props.mainBlue),
      imports: commonPopperContainerSettings(props.mainBlue),
      userPermissions: commonPopperContainerSettings(props.mainBlue),
      importNumberOfRows: {
        ...commonPopperContainerSettings(props.mainBlue),
        px: 33,
        borderRadius: 'none',
      },
    },
    text: {
      common: commonTextSettings(props),
      userPermissions: commonTextSettings(props),
      imports: {
        ...commonTextSettings(props),
        py: 10,
      },
      importNumberOfRows: {
        ...commonTextSettings(props),
        py: '5px',
        textTransform: 'capitalize',
      },
    },
    icon: {
      common: commonIconSettings,
      imports: commonIconSettings,
      userPermissions: commonIconSettings,
      importNumberOfRows: commonIconSettings,
    },
    filterIcon: {
      common: commonFilterIconSettings(props.mainLight),
      imports: commonFilterIconSettings(props.mainLight),
      userPermissions: commonFilterIconSettings(props.mainLight),
      importNumberOfRows: commonFilterIconSettings(props.mainLight),
    },
  }
);
