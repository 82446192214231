import * as R from 'ramda';
import React from 'react';
import { Field } from 'redux-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// forms
import { renderSelectGroup, renderInputGroup } from '../../../../forms';
// ui
import { Box, Span, Flex } from '../../../../ui';
// feature edit-report
import { customRequired } from '../../validation';
//////////////////////////////////////////////////

const fieldSettings = {
  width: 109,
  margin: 10,
  afterTop: 22,
  afterRight: 22,
  labelWidth: 120,
  padding: '0 5px',
  direction: 'row',
  labelMargin: '0 11px',
  labelPosition: 'left',
  labelFontSize: '14px',
};

const ReportSummary = (props: Object) => {
  const groupByOptions = props.groupByOptions;
  const summaryOptions = R.prepend({ name: '', value: null }, props.summaryOptions);

  return (
    <Box width='100%'>
      <Flex
        p='0 15px'
        height={40}
        borderBottom='1px solid'
        justifyContent='space-between'
        color={G.getTheme('colors.light.black')}
        bg={G.getTheme('colors.light.lightGrey')}
        borderColor={G.getTheme('colors.lightGrey')}
      >
        <Span>{G.getWindowLocale('titles:settings', 'Settings')}</Span>
      </Flex>
      <Box>
        <Field
          {...fieldSettings}
          type='text'
          errorLeft='0'
          align='center'
          errorTop='35px'
          jc='space-between'
          name='showLastDays'
          mainDirection='column'
          errorPosition='absolute'
          component={renderInputGroup}
          // NOTE: check this validation
          // validate={prevDaysValidation}
          label={`${G.getWindowLocale('titles:days-before', 'Days Before')}: `} />
        <Field
          {...fieldSettings}
          type='select'
          jc='space-between'
          name={GC.FIELD_GROUP_BY}
          options={groupByOptions}
          component={renderSelectGroup}
          label={`${G.getWindowLocale('titles:organize-by', 'Organize By')}: `} />
        <Flex justifyContent='space-between'>
          <Field
            {...fieldSettings}
            zIndex={12}
            type='select'
            jc='space-between'
            options={summaryOptions}
            validate={customRequired}
            name={GC.FIELD_SUMMARY_GROUP}
            component={renderSelectGroup}
            label={`${G.getWindowLocale('titles:summary', 'Summary')}: `} />
          <Field
            {...fieldSettings}
            zIndex={11}
            type='select'
            labelMargin='0'
            options={summaryOptions}
            component={renderSelectGroup}
            name={GC.FIELD_SUMMARY_SUBGROUP} />
        </Flex>
      </Box>
    </Box>
  );
};

export default ReportSummary;
